<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Provincia</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px"  :disabled="booltienePirineos">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Nuevo</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm12 md12>
                                    <v-text-field disabled v-model="id" label="Código" box></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-select :options="paises" v-on:change="onchange()" v-model="idpais"
                                    :items="paises" label="Paises" box>
                                    </v-select>
                                </v-flex>
                                
                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="descripcion" label="Provincia" box></v-text-field>
                                </v-flex>  

                                 <v-flex xs12 sm12 md12  v-if=boolCliente>
                                    <v-select  v-model="idcliente"
                                    :items="comboCliente" label="Cliente Oclock" box>
                                    </v-select>
                                </v-flex>                              
                                
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                    </div>
                                </v-flex>
                            
                                </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="guardar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==2">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==1">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==2">Activar </span>
                                <span v-if="adAccion==1">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="provincias"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                        v-if=!booltienePirineos
                        small
                        class="mr-2"
                        @click="editItem(props.item)"

                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.baja">
                            <v-icon
                            v-if=!booltienePirineos
                            small
                            @click="activarDesactivarMostrar(2,props.item)"

                            >
                            block
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            v-if=!booltienePirineos
                            small
                            @click="activarDesactivarMostrar(1,props.item)"

                            >
                            check
                            </v-icon>
                        </template>
                    </td>
                    <td style="display: none">{{ props.item.idPais }}</td>
                    <td style="display: none">{{ props.item.id }}</td>
                    <td style="display: none">{{ props.item.idClienteOclock }}</td>
                    <td>{{ props.item.clienteOclock }}</td>
                    <td>{{ props.item.nomPais }}</td>
                    <td>{{ props.item.descripcion }}</td>
                    <td>
                        <div v-if="props.item.baja">
                            <span class="red--text">Inactivo</span>
                        </div>
                        <div v-else>
                            <span class="blue--text">Activo</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">

                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import Vue from 'vue';

    export default {
        data(){
            return {
                provincias:[],               
                paises:[], 
                comboCliente:[],
                dialog: false,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Empresa', value: 'clienteOclock'},
                    { text: 'Pais', value: 'nomPais' },
                    { text: 'Provincia', value: 'descripcion' },                    
                    { text: 'Estado', value: 'baja', sortable: false  }                
                ],
                search: '',
                editedIndex: -1,
                idpais: '',
                id:'',
                nompais: '',
                descripcion:'',
                idclienteoclock:'',
                valida: 0,
                validaMensaje:[],
                bolExiste:false,
                EstoyEditando:false,
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: '',
                pagination: {rowsPerPage: 12},
                searchInput: "",
                tienePirineos:false,
                capturaCodigoPais:'',
                idcliente:'',
                codigocliente:'',
                boolCliente:'',
                booltienePirineos:''  
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nueva Provincia' : 'Actualizar Provincia'
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            this.selectPaises();
            this.pirineos();
            this.selectCliente();
        },
        methods:{
            listar(){
               let me=this;
               me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Provincias/Listar', {
                    'idclienteoclock':me.codigocliente               
                })
                .then(function(response){
                    //Verifica si el usuario es Master.
                     axios.post('api/ClienteOclocks/OcultarCliente',{
                        'codigo':me.codigocliente  
                        }).then(function(response){
                            me.boolCliente=response.data;
                        }).catch(function(error){
                            console.log(error);
                        }); 
                    ///
                    me.provincias=response.data;                                   
                })
                .catch(function(error){
                    console.log(error);
                });
            },
            editItem (item) {
                this.id=item.id;
                this.idpais=item.idPais;
                this.descripcion=item.descripcion;
                this.codigo=item.idClienteOclock;
                this.editedIndex=1;
                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
                this.idpais="";
                this.descripcion="";
                this.id="";
                this.nompais="";
                this.editedIndex=-1;
                this.validaMensaje="";
            },

            guardar () {
                let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;

                if (this.validar()){
                    return;
                }

                this.tipoRol();
                
                    axios.post('api/Provincias/ExisteProvincia',{
                        'descripcion': me.descripcion,
                        'idclienteoclock':me.codigocliente                          
                    })                         
                    .then((response) => {                               
                        if(this.editedIndex > -1){//La data devuelve true si la empresa ya existe
                             this.EstoyEditando=true;                         
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data;     
                            
                            Vue . swal ("La provincia: "+ this.descripcion + " ya está registrada");                          
                        }

                        if(this.EstoyEditando==true ){                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar
                                let me=this;
                                axios.post('api/Provincias/Actualizar',{
                                     'id':me.id,
                                     'idPais': this.capturaCodigoPais,
                                     'Descripcion': this.descripcion,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                    
                            if (this.EstoyEditando==false && this.bolExiste==false){
                                //Código para guardar
                                
                                let me=this;

                                 if(this.$store.state.usuario.rol=="Ecomputer")
                                 {      
                                    this.codigocliente = this.idcliente;
                                 }
                                 else
                                 {
                                    this.codigocliente = this.$store.state.usuario.idclienteoclock;
                                 }

                                axios.post('api/Provincias/Crear',{
                                  'idPais': this.capturaCodigoPais,
                                  'descripcion':this.descripcion,
                                  'idclienteoclock':this.codigocliente
                                 
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                    })
                        .catch((response) => {
                        console.log(response);
                    })
                    .then(() => {

                     });
            },

            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.descripcion.length<3 || this.descripcion.length>50){
                    this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.descripcion;
                this.adId=item.id;    
                console.log(this.adId);            
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                axios.post('api/Provincias/Activar',{
                  'Id':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(){
                let me=this;
                axios.post('api/Provincias/Desactivar',{
                  'Id':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            selectPaises(){
                let me=this;
                var paisesArray=[];
                axios.post('api/Paises/SelectPaises',{
                'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    paisesArray=response.data;
                    paisesArray.map(function(x){
                    me.paises.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            }, 
            

            onchange: function() {
            this.capturaCodigoPais = this.idpais;
            },

            selectCliente(){
                let me=this;
                var clienteArray=[];
                axios.get('api/ClienteOclocks/SelectCliente')
                .then(function(response){
                    clienteArray=response.data;
                    clienteArray.map(function(x){
                    me.comboCliente.push({text: x.descripcion,value:x.codigo});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },

            tipoRol()
            {
                if(this.$store.state.usuario.rol=="Ecomputer")
                {      
                    this.codigocliente = this.idcliente;
                }
                 else
                {
                    this.codigocliente = this.$store.state.usuario.idclienteoclock;
                }
            },

            pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },

        
        }        
    }
</script>
