<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Horario Empresa</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Nuevo</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
            
                                <v-container>
                                    <v-layout row wrap>

                                        <v-flex xs12 sm6>
                                           <v-text-field disabled v-model="idHorario" label="Código" box ></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6 d-flex>
                                            <v-select v-model="codEmpresa"
                                                :items="empresas"
                                                filled
                                                label="Empresa"
                                                box
                                            ></v-select>
                                        </v-flex>


                                        <v-flex xs12 sm6>
                                             <v-text-field type="time"  v-model="horaInicioManana" label="Hora Inicio Mañana" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6>
                                             <v-text-field type="time"  v-model="horaFinManana" label="Hora Fin Mañana" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6>
                                             <v-text-field type="time" v-model="horaInicioTarde" label="Hora Inicio Tarde" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6>
                                             <v-text-field type="time" v-model="horaFinTarde" label="Hora Fin Tarde" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm12 md12 v-show="valida">
                                            <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                            </div>
                                        </v-flex>

                                    </v-layout>
                                </v-container>
                       
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="success" flat @click.native="guardar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==1">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==2">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==1">Activar </span>
                                <span v-if="adAccion==2">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="horarios" 
                :search="search"
                class="elevation-1" 
                 :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.condicion">
                            <v-icon
                            small
                            @click="activarDesactivarMostrar(2,props.item)"
                            >
                            block
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            small
                            @click="activarDesactivarMostrar(1,props.item)"
                            >
                            check
                            </v-icon>
                        </template>
                    </td>
                    <td style="display: none">{{ props.item.idHorario }}</td>
                    <td style="display: none">{{ props.item.codEmpresa }}</td>
                    <td>{{ props.item.nomEmpresa }}</td>
                    <td>{{ props.item.horaInicioManana }}</td>
                    <td>{{ props.item.horaFinManana }}</td>
                    <td>{{ props.item.horaInicioTarde }}</td>
                    <td>{{ props.item.horaFinTarde }}</td>
                    <td>
                        <div v-if="props.item.condicion">
                            <span class="blue--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="red--text">Inactivo</span>
                        </div>
                    </td>
                
                </template>
                <template slot="no-data">
                <v-btn color="primary" @click="listar">Resetear</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import Vue from 'vue';
    export default {
        data(){
            return {
                horarios:[], 
                empresas:[],                  
                dialog: false,
                headers: [//Creamos la cabecera del datatable
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Empresa', value: 'nomEmpresa'},
                    { text: 'Mañana Inicio', value: 'horaInicioManana'},
                    { text: 'Mañana Fin', value: 'horaFinManana', sortable: false  },
                    { text: 'Tarde Inicio', value: 'horaInicioTarde', sortable: false  },
                    { text: 'Tarde Fin', value: 'horaFinTarde', sortable: false  }  ,
                    { text: 'Estado', value: 'condicion', sortable: false  }                 
                ],
                search: '',
                editedIndex: -1,
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: ''  ,
                
                idHorario:'',
                codEmpresa:'',
                nomEmpresa:'',
                horaInicioManana:'',
                horaFinManana:'',
                horaInicioTarde:'',
                horaFinTarde:'',
                EstoyEditando:'',

                valida: 0,
                validaMensaje:[],
                pagination: {rowsPerPage: 12},
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuevo Horario' : 'Actualizar Horario'
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            this.selectEmpresa();
        },
        methods:{
            listar(){
                let me=this;//Esto para poder utilizar el this dentro de cualquier llamado acciones
                axios.get('api/HorarioEmpresas/Listar').then(function(response){
                    me.horarios=response.data;//me.usuarios es un array. El objeto devuelto esta en un objeto raíz en este caso data.
                }).catch(function(error){
                    console.log(error);
                });
            },
            selectEmpresa(){
                let me=this;
                var EmpresaArray=[];
                axios.get('api/HorarioEmpresas/SelectEmpresa').then(function(response){
                    EmpresaArray=response.data;
                    EmpresaArray.map(function(x){
                    me.empresas.push({text: x.nomEmpresa,value:x.codEmpresa});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
            editItem (item) {
                this.idHorario=item.idHorario;
                this.codEmpresa=item.codEmpresa;
                this.horaInicioManana =item.horaInicioManana;
                this.horaFinManana = item.horaFinManana;
                this.horaInicioTarde = item.horaInicioTarde;
                this.horaFinTarde = item.horaFinTarde;

                this.editedIndex=-1;

                this.editedIndex=1;
                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
                this.idHorario="";
                this.codPersonal="";
                this.codEmpresa="";
                this.horaInicioManana="";
                this.horaFinManana="";
                this.horaInicioTarde="";
                this.horaFinTarde="";
                this.validaMensaje="";


                this.editedIndex=-1;
            },
            guardar () {

                let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;

                if (this.validar()){
                    return;
                }
                    axios.post('api/HorarioEmpresas/ExisteEmpresa',{'codEmpresa': me.codEmpresa})                 
                    .then((response) => {                         
                        if(this.editedIndex > -1){//La data devuelve true si la empresa ya existe
                             this.EstoyEditando=true;                            
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data;     
                           Vue . swal ("La empresa ya tiene un horario asignado"); 
                        }

                        if(this.EstoyEditando==true){                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar
                                let me=this;
                                axios.post('api/HorarioEmpresas/Actualizar',{
                                    'idHorario':me.idHorario,
                                    'codEmpresa':me.codEmpresa,
                                    'horaInicioManana':me.horaInicioManana,
                                    'horaFinManana':me.horaFinManana,
                                    'horaInicioTarde':me.horaInicioTarde,
                                    'horaFinTarde':me.horaFinTarde,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                    
                            if (this.EstoyEditando==false && this.bolExiste==false){
                                //Código para guardar
                                let me=this;
                                if(me.horaInicioManana=="")
                                {
                                   me.horaInicioManana='00:00'                                  
                                }

                                if(me.horaFinManana=="")
                                {
                                   me.horaFinManana='00:00'                                  
                                }

                                if(me.horaInicioTarde=="")
                                {
                                   me.horaInicioTarde='00:00'                                  
                                }

                                if(me.horaFinTarde=="")
                                {
                                   me.horaFinTarde='00:00'                                  
                                }

                                axios.post('api/HorarioEmpresas/Crear',{
                                 'codEmpresa':me.codEmpresa,
                                 'horaInicioManana':me.horaInicioManana,                                 
                                 'horaFinManana':me.horaFinManana,
                                 'horaInicioTarde':me.horaInicioTarde,
                                 'horaFinTarde':me.horaFinTarde,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                    })
                        .catch((response) => {
                        console.log(response);
                    })
                    .then(() => {

                     });
            },

             validar(){
                 let me=this;   
                this.valida=0;
                this.validaMensaje=[];

                if (this.codEmpresa == 0 ){
                    this.validaMensaje.push("Rellene todos los campos");
                    this.valida=1;
                }

                return this.valida;
            },

            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nomEmpresa;
                this.adId=item.idHorario;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
               axios.post('api/HorarioEmpresas/Activar',{
                  'IdHorario':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(){
                let me=this;
                axios.post('api/HorarioEmpresas/dESACTIVAR',{
                  'IdHorario':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
        }        
    }
</script>

<<style>

.colorCabecera {
    color:blue !important;
}

.Desabilitar{
    text:disabled !important;
}

</style>