<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
               
               <!-- <div>
                    <Upload action="" :before-upload="beforeUpload">
                    <Button icon="ios-cloud-upload-outline">Excel</Button>
                    </Upload>
                </div> -->

                
                <v-toolbar-title>Informe General Fichaje</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>            
                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                        <!-- <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-model="codPersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal" box>
                                            </v-select>
                                        </v-flex>      -->
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="reportemensual"
                :search="search"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                    <td style="display: none">>{{ props.item.codPersonal}}</td>
                    <td style="display: none">{{ props.item.fecha | filterDate }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.diasTrabajados }}</td>
                    <td style="display: none">{{ props.item.nomEmpresa }}</td>
                    <td style="display: none">{{ props.item.nomDepartamento }}</td>
                    <td  style="display: none">{{ props.item.minutosTrabajados }}</td>
                    <td>{{ props.item.tiempoPresente }}</td>
                    <td>{{ props.item.tiempoIncidencia }}</td>
                    <td style="display: none">{{ props.item.minutosPorTrabajar }}</td>
                    <td>{{ props.item.diferenciaMinutos }}</td>
                    <td style="display: none">{{ props.item.totalHoras }}</td>
                    <td style="display: none">{{ props.item.tipo }}</td>
                    <td style="display: none">{{ props.item.tipoIncidencia }}</td>
                </template>


                
                
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import moment from "moment";
    import XLSX from 'xlsx'
    import Vue from 'vue';

    function readXLSX (file) {
        let nameSplit = file.name.split('.')
        let format = nameSplit[nameSplit.length - 1]
        if (!['xlsx', 'csv'].includes(format)) {
            return false
        }
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.readAsBinaryString(file)
            reader.onload = function (evt) {
            let data = evt.target.result
            var workbook = XLSX.read(data, { type: 'binary' })
            resolve(workbook)
            }
        })
    }



    export default {
        //Este filtro es util para darle formato a un valor y meterlo en la tabla
        filters: {
            filterDate: function (value) {
                //return moment(value).format('DD/MM/YYYY h:mm')
                return moment(value).format('DD/MM/YYYY')
            },

            minutos: function (value) {
                return this.minutosTrabajados();
            }

        },
        data(){
            return {
                usuarios:[], 
                reportemensual:[],                
                dialog: false,
                headers: [
                    // { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Empleado', value: 'nombre' },
                    { text: 'Días Trabajados', value: 'diasTrabajados' },                           
                    { text: 'Total Min Trabajados', value: 'tiempoPresente' },
                    { text: 'Total Min Incidencia', value: 'tiempoIncidencia' },                   
                    { text: 'Diferencia Minutos', value: 'diferenciaMinutos' },    

                ],
               search: '',
                editedIndex: -1,
                id: '',
                idrol:'',
                idfichaje:'',
                tipo:'',
                fecha:'',
                nombre:'',
                apellido1:'',
                apellido2:'',
                fechaInicio: '',
                fechaFin: '',
                fechaInicio1: '',
                fechaFin1: '',
                pagination: {rowsPerPage: 12},
                codPersonal:'',
                diasTrabajados:'',
                minutosIncidencias:'',
                minutosPersonales:'',
                minutosSalidas:'',
                minutosFavorContraPersonal:'',
                horasTrabajadas:'',
                capturaFechaIncicio:'',
                capturaFechaFin:'',
                capturaNombre:'',
                capturaEmpresa:'',
                capturaDepartamento:''
            }
        },
       
        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
             //this.selectPersonal();

        },
        methods:{
   

            crearPDF(){
                var columns = [
                    
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Días Trabajados", dataKey: "diasTrabajados"},                     
                    {title: "Minutos Trabajados", dataKey: "tiempoPresente"},   
                    {title: "Minutos Incidencia", dataKey: "tiempoIncidencia"},         
                    {title: "Diferencia Minutos", dataKey: "diferenciaMinutos"},
                ];


                var rows = [];
                let me=this;
               
                this.reportemensual.map(function(x){
                    //rows.push({fecha:moment(x.fecha).format("DD/MM/YYYY"),minutosTrabajados:x.minutosTrabajados,minutosPorTrabajar:x.minutosPorTrabajar,tiempoPresente:x.tiempoPresente,tiempoIncidencia:x.tiempoIncidencia,totalHoras:x.totalHoras});                    
                    rows.push({nombre:x.nombre,diasTrabajados:x.diasTrabajados,tiempoPresente:x.tiempoPresente,tiempoIncidencia:x.tiempoIncidencia,diferenciaMinutos:x.diferenciaMinutos});                    
                });
                  // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 100},
                    addPageContent: function(data) {
                        doc.text("Informe de General Fichaje:   " +moment(me.capturaFechaIncicio ).format('DD/MM/YYYY')+" hasta "+moment(me.capturaFechaFin).format('DD/MM/YYYY'), 40, 30);
                        
                        doc.setFontSize(12);
                        doc.setTextColor(40);
                        doc.setFontStyle('normal');
                        doc.text("Empresa: :  "+ me.capturaEmpresa,40,60);

                    }
                });
                doc.save('Informe_General_Fichajes.pdf');
            },

            
            close () {
                this.dialog = false;
            },

            footer(){ 
                doc.text(150,285, 'page ' + doc.page); //print number bottom right
                doc.page ++;
            },

           
            filtrarfechas(){
                
                let me=this;
                this.fechaInicio1= me.fechaInicio,
                this.fechaFin1 = me.fechaFin

                axios.post('api/Informes/InformeGeneralFichajes',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.reportemensual=response.data;

                    if(me.reportemensual=="")
                    {
                        Vue . swal ("!!! No hay datos que mostrar !!!");     
                    }
                    else
                    {
                        me.reportemensual=response.data;
                        me.capturaFechaIncicio=me.fechaInicio;
                        me.capturaFechaFin=me.fechaFin;
                        me.capturaNombre = me.reportemensual[0].nombre;
                        me.capturaEmpresa = me.reportemensual[0].nomEmpresa;                      
                        me.close();
                        me.limpiar();   
                    }
                                            
                }).catch(function(error){
                    console.log(error);
                });
            },

            Excel(){
                let me=this;
                me.capturaCodPersonal = this.codpersonal; 

                axios.post('api/Informes/InformeGeneralFichajesExcel', {
                   'fecha1':me.fechaInicio1,
                   'fecha2':me.fechaFin1,
                   'Personal':me.capturaCodPersonal,
                   'idclienteoclock':this.$store.state.usuario.idclienteoclock              
                })
                .then(function(response){
                    //me.empresas=response.data;   
                    Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");                                             
                })
                .catch(function(error){
                    console.log(error);
                });
            },



            selectPersonal(){
                let me=this;
                var personalArray=[];
                //me.codPersonal = this.$store.state.usuario.includes
                me.codPersonal = this.$store.state.usuario.id
                axios.post('api/Notificaciones/SelectPersonal',{
                 'IdPersonal':me.codPersonal,
                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.usuarios.push({text: x.nombre,value:x.codPersonal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            },
            
            limpiar(){
               this.fechaInicio="";
               this.fechaFin="";
               this.codPersonal="";
            },

            

        }        
    }
</script>