<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Servidor OClock</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-btn  slot="activator" color="primary" dark class="mb-2" >Nuevo</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm12 md12 >
                                    <v-select  v-model="nameConex"
                                    :items="comboServer" label="Conexiones" box>
                                    </v-select>
                                  </v-flex>      


                                 <v-flex xs12 sm12 md12>
                                    <v-select  v-model="idcliente"
                                    :items="comboCliente" label="Cliente Oclock" box>
                                    </v-select>
                                </v-flex>                

                                </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" slot="activator" flat @click.native="guardar">Guardar</v-btn>                              
                            </v-card-actions>
                        </v-card>
                    </v-dialog>               
                    
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==1">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==2">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==1">Activar </span>
                                <span v-if="adAccion==2">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>                    

                    
                </v-toolbar>
            
            <v-data-table
                :headers="headers"
                :items="servidor"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.low">
                            <v-icon
                            small
                            @click="activarDesactivarMostrar(2,props.item)"
                            >
                            check
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            small
                            @click="activarDesactivarMostrar(1,props.item)"
                            >
                            block
                            </v-icon>
                        </template>
                        <template>
                            <v-icon
                            small
                            @click="sincronizarPirineos(3,props.item)"
                            >
                            sync
                            </v-icon>
                        </template>
                    </td>
                    <td style="display: none">{{ props.item.idServer }}</td>
                    <td>{{ props.item.idClienteOclock }}</td>
                    <td>{{ props.item.clientName }}</td>
                    <td>{{ props.item.conexion }}</td>
                     <td>
                        <div v-if="props.item.low">
                            <span class="blue--text">Si</span>
                        </div>
                        <div v-else>
                            <span class="red--text">No</span>
                        </div>
                    </td>
                    
                </template>
                <template slot="no-data">
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import Vue from 'vue';
    import Multiselect from 'vue-multiselect'
   // import {tienePirineos} from '@/mixins.js'

    export default {
        data(){
            return {
                servidor:[],               
                comboCliente:[],   
                comboServer:[],  
                isLoading: false,     
                dialog: false,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },             
                    { text: 'Id Cliente', value: 'idClienteOclock', sortable: false  },       
                    { text: 'Cliente', value: 'clientname', sortable: false  },                                
                    { text: 'Conexión', value: 'conexion', sortable: false  },  
                    { text: 'Activo', value: 'low', sortable: false  },  
                ],
                search: '',
                editedIndex: -1,
                idserver: '',
                idClienteOclock: '',
                cadena: '',
                clientname:'',
                low:'',
                valida: 0,
                validaMensaje:[],
                bolExiste:false,
                EstoyEditando:false,
                adModal: 0,
                sincronizar:0,
                adAccion: 0,
                adNombre: '',
                adId: '',
                des:'',
                pagination: {rowsPerPage: 12},     
                idcliente:'',                
                codigocliente:'',
                boolCliente:''  ,
                boolOcultarColumna:'',    
                clienteSincronizar:'',
                nameConex:''                  
            }
        },

        //mixins:[tienePirineos],      
        components: {
            Multiselect
        },  

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuevo Server OClock' : 'Actualizar Server OClock'
            },
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            this.selectCliente();
            this.conexiones();
        },
        methods:{
            listar(){
                let me=this;
                axios.get('api/Servidores/List').then(function(response){
                me.servidor=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

           conexiones(){
                let me=this;
                var serverArray=[];
                axios.get('api/Servidores/ListaConexiones')
                .then(function(response){
                    serverArray=response.data;
                    serverArray.map(function(x){
                    me.comboServer.push({text: x.value,value:x.codigo});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
            

            editItem (item) {
                let me = this;
                this.idserver=item.idServer;
                this.idcliente=item.idClienteOclock;
                this.nameConex=item.nameConex;
                this.low=item.low;
                
                if(this.low==true)
                {
                    me.des = "Si"
                }
                else
                {
                    me.des="No";
                }
                
                this.editedIndex=-1;
                this.editedIndex=1;

                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
                this.idServer="";
                this.idclienteoclock="";
                this.nameConex="";
                this.editedIndex=-1;
            },

            guardar () {
                let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;
                 

                    axios.post('api/Servidores/ExistDataBase',{'basename': me.basename})                                
                    .then((response) => {      
                         console.log(response.data);                               
                        if(this.editedIndex > -1){//La data devuelve true si la empresa ya existe
                             this.EstoyEditando=true;                         
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data; 
                           Vue . swal ("La base de datos: "+ this.servername + " ya esta registrada");                             
                        }

                        if(this.EstoyEditando==true){                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar

                                axios.post('api/Servidores/Update',{
                                     'IdServer':me.idserver,
                                     'idclienteoclock': me.idcliente,
                                     'conexion': me.nameConex,
                                     'low':me.low
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                    
                            if (this.EstoyEditando==false && this.bolExiste==false){
                                //Código para guardar
                                
                                axios.post('api/Servidores/Create',{
                                    'idclienteoclock': me.idcliente,
                                     'conexion': me.nameConex,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                    })
                        .catch((response) => {
                        console.log(response);
                    })
                    .then(() => {

                     });
            },

            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adId=item.idServer; 
                this.adNombre=item.clientName;               
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },

            activarDesactivarCerrar(){
                this.adModal=0;
            },

            activar(){
                let me=this;
                axios.post('api/Servidores/Active',{
                  'idserver':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";                    
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });

            },
            desactivar(){
                let me=this;
                  axios.post('api/Servidores/Desactive',{
                  'idserver':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                     
                }).catch(function(error){
                    console.log(error);
                });
            }, 

            selectCliente(){
                let me=this;
                me.isLoading = true
                var clienteArray=[];
                axios.get('api/ClienteOclocks/SelectCliente')
                .then(function(response){
                    clienteArray=response.data;
                    me.isLoading = false
                    clienteArray.map(function(x){
                    me.comboCliente.push({text: x.descripcion,value:x.codigo});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },

             tipoRol()
            {
                if(this.$store.state.usuario.rol=="Ecomputer")
                {      
                    this.codigocliente = this.idcliente;
                }
                 else
                {
                    this.codigocliente = this.$store.state.usuario.idclienteoclock;
                }
            },

            sincronizarPirineos(accion,item)
            {
                 let me=this;
                  me.clienteSincronizar=item.idClienteOclock

                    Vue . swal({
                    title: '¿Desea Sincronizar Pirineos?',
                    text:"",
                    showCancelButton: true,
                    confirmButtonText: 'Si!',
                    cancelButtonText: 'No!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                    }).then((result) => {
                    if(result.value) {

                        axios.post('api/Servidores/SincronizaPirineos',{
                        'idclienteoclock': me.clienteSincronizar
                        }).then(function(response){
                           
                        }).catch(function(error){
                            console.log(error);
                        });                        

                        Vue . swal('Sincronización', 'Sincronización completa', 'success')                   
                    } else {
                        Vue . swal('Cancelada', 'Sincronización cancelada', 'info')
                    }
                    })    
                     this.listar();                          
            },   

        }        
    }
</script>

<style>


</style>
