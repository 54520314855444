<template>
  <v-app id="app">
    <v-navigation-drawer
      fixed
      :clipped="$vuetify.breakpoint.mdAndUp"
      app
      v-model="drawer"
      width="210"
      v-if="logueado">
      <v-list dense>        
        <template v-if="esAdministrador ||  esUsuario  || esEcomputer"  >
          <v-list-tile :to="{name:'home'}">
            <v-list-tile-action>
               <v-icon large color="blue">home</v-icon>
            </v-list-tile-action> 
            <v-list-tile-title style="color: blue">
              Inicio
            </v-list-tile-title>
          </v-list-tile>
        </template>

        <template v-if="esAdministrador || esUsuario || esEcomputer">
          <v-list-tile :to="{name:'misdatos'}">
            <v-list-tile-action>
               <v-icon large color="blue">add_alarm</v-icon>
            </v-list-tile-action>
            <v-list-tile-title style="color: blue">
              FICHAJE
            </v-list-tile-title>
          </v-list-tile>
        </template>

         <v-divider></v-divider>  

         <template v-if="esEcomputer">
             <v-list-tile :to="{ name: 'clienteOClock'}">
              <v-list-tile-action>
                <v-icon large color="blue">supervised_user_circle</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Cliente OClock
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

        <template v-if="esEcomputer">
             <v-list-tile :to="{ name: 'servidor'}">
              <v-list-tile-action>
                <v-icon large color="blue">storage</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Servidor OClock
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>
       

        <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'paises'}">
              <v-list-tile-action>
                <v-icon large color="blue">public</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Países
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>


      <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'provincias'}">
              <v-list-tile-action>
                <v-icon large color="blue">public</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Provincias
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

         <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'poblaciones'}">
              <v-list-tile-action>
                <v-icon large color="blue">public</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Municipios
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

        <v-divider></v-divider>        

        <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'empresas'}">
              <v-list-tile-action>
                <v-icon large color="blue">business</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Empresa
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

      <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'centros'}">
              <v-list-tile-action>
                <v-icon large color="blue">store</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Centros
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

        <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'departamentos'}">
              <v-list-tile-action>
                <v-icon large color="blue">account_box</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Departamento
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

         <!-- <template v-if="esAdministrador">
             <v-list-tile :to="{ name: 'horarioEmpresas'}">
              <v-list-tile-action>
                <v-icon large color="blue">alarm</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Horario Empresa
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template> -->

        <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'usuarios'}">
              <v-list-tile-action>
                <v-icon large color="blue">person_add</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Personal
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

          <template v-if="esAdministrador ||  esUsuario  || esEcomputer">
             <v-list-tile :to="{ name: 'vacaciones'}">
              <v-list-tile-action>
                <v-icon large color="blue">insert_emoticon</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Vacaciones
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>


       <!-- <template v-if="esAdministrador">
             <v-list-tile :to="{ name: 'roles'}">
              <v-list-tile-action>
                <v-icon large color="blue">assignment_ind</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Roles
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template> -->


       

       <!--  <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'nominas'}">
              <v-list-tile-action>
                <v-icon large color="blue">euro_symbol</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Nominas
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template> -->

         <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'notificaciones'}">
              <v-list-tile-action>
                <v-icon large color="blue">send</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Notificación
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

         <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'informePush'}">
              <v-list-tile-action>
                <v-icon large color="blue">textsms</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Informe Notificación
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

       <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'presentes'}">
              <v-list-tile-action>
                <v-icon large color="blue">group_add</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Presentes
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

       <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'incidencias'}">
              <v-list-tile-action>
                <v-icon large color="blue">contact_support</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Incidencia
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

        <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'causas'}">
              <v-list-tile-action>
                <v-icon large color="blue">contact_phone</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Causas
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

         <template v-if="esAdministrador || esUsuario || esEcomputer">
             <v-list-tile :to="{ name: 'fichajemanual'}">
              <v-list-tile-action>
                <v-icon large color="blue">group_add</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Fichaje Manual
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

        <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'validarFichajes'}">
              <v-list-tile-action>
                <v-icon large color="blue">done_outline</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Validar Fichajes
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

         <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'clavesasignadas'}">
              <v-list-tile-action>
                <v-icon large color="blue">vpn_key</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Claves Asignadas
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

         <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'sesionesmoviles'}">
              <v-list-tile-action>
                <v-icon large color="blue">screen_lock_rotation</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Sesiones Móviles
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template>

       <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'mapeomoviles'}">
              <v-list-tile-action>
                <v-icon large color="blue">settings_input_antenna</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Mapeo Móviles
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
        </template> 

        <!-- <template v-if="esAdministrador || esEcomputer">
             <v-list-tile :to="{ name: 'trazabilidadmovil'}">
              <v-list-tile-action>
                <v-icon large color="blue">connect_without_contact</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Trazabilidad Móvil
                </v-list-tile-title>
              </v-list-tile-content>

            </v-list-tile>
        </template> -->

         <template v-if="esAdministrador || esEcomputer">
          <v-list-group>
            <v-list-tile slot="activator">
              <v-icon large color="blue">assignment</v-icon>              
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">                  
                   Informes
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

             <v-list-tile :to="{ name: 'InformeGeneral'}">
              <v-list-tile-action>
                <v-icon large color="blue">chrome_reader_mode</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                   General
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>   

            <v-list-tile :to="{ name: 'informeGeneralFichaje'}">
              <v-list-tile-action>
                <v-icon large color="blue">chrome_reader_mode</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                   General Fichajes
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>                

             <v-list-tile :to="{ name: 'InformeFichaje'}">
              <v-list-tile-action>
                <v-icon large color="blue">chrome_reader_mode</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                   Fichajes
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
                                    
          <v-list-tile :to="{ name: 'InformeIncidencia'}">
              <v-list-tile-action>
                <v-icon large color="blue">chrome_reader_mode</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                   Excepciones
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>                                   
          </v-list-group>                     
        </template>

        

     <!-- <template v-if="esAdministrador || esSuperUsuario || esUsuario">
         <v-list-tile :to="{ name: 'nominas'}">
              <v-list-tile-action>
                <v-icon large color="blue">euro_symbol</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title style="color: blue">
                  Nominas
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>    
      </template> -->

    <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      color="blue darken-3"
      dark
      app
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      fixed
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
        <span class="hidden-sm-and-down">Sistema</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="salir" v-if="logueado" icon>
        <v-icon>logout</v-icon> Salir
      </v-btn>
      <v-btn :to="{name: 'login'}" v-else>
        <v-icon>apps</v-icon> Login
      </v-btn>
    </v-toolbar>
    <v-content>
      <v-container fluid fill-height class="prueba">
        <v-slide-y-transition mode="out-in">
          <router-view/>
        </v-slide-y-transition>
      </v-container>
    </v-content>
    <v-footer dark height="auto">
      <v-layout justify-center>
        <v-flex text-xs-center>
          <v-card flat tile color="primary" class="white--text">
            <v-card-text class="white--text pt-0">
              &copy; {{anno}} Copyright: {{esCliente }}
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>

  </v-app>
</template>

<script>

import {mapActions} from 'vuex'
import axios from 'axios'
import {tienePirineos} from '@/mixins.js'


export default {
  name: 'App',
  //mixins: ['pirineosMixin'],
  data () {
    return {
      //usuario: this.$store.state.usuario.nombre,
      usuario: 'xhhhx',
      clipped: false,
      drawer: true,
      fixed: false,
      items: [{
        icon: 'bubble_chart',
        title: 'Inspire'
      }],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js',
      anno:'',
      nombre:'',
      codigocliente:'',
      cliente:''
    }
  },
  computed: {
    logueado(){ 
      //usuario = this.$store.state.usuario;
      return this.$store.state.usuario;
    },

    esAdministrador(){
      //console.log(this.$store.state.usuario);
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
    },

    esUsuario(){
      return this.$store.state.usuario && this.$store.state.usuario.rol =='Usuario';
    },

    esEcomputer(){
       return this.$store.state.usuario && this.$store.state.usuario.rol =='Ecomputer';    
    },

    esCliente(){
     if(this.$store.state.usuario != null)
     {
       return  this.$store.state.usuario.descripcion;   
     }else{
        return "";
     }
    },


  },
  created(){
    this.$store.dispatch("autoLogin");
    this.devolverAno();
    this.vinculado();
  },

  methods:{
    salir(){
      this.$store.dispatch("salir");
      this.cliente="";
    },

    devolverAno(){
     let me = this; 
     var fecha = new Date();
     me.anno = fecha.getFullYear();
    },

    vinculado(){}
  


  }
}
</script>

<style>
.nombreuser {
  font-size: 20px;
  margin-right: 10px;
}
.prueba {
  background: url(/oclockFondo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
</style>