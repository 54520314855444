<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Cliente OClock</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" :disabled="tienePirineos">
                        <v-btn  slot="activator" color="primary" dark class="mb-2" >Nuevo</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="descripcion" label="Descripción" box></v-text-field>
                                </v-flex>                     

                                <v-flex xs12 sm12>
                                    <v-text-field v-model="fechaAlta" label="Fecha Alta" dateformat="d M y" type="date" box></v-text-field>
                               </v-flex>

                               <v-flex xs12 sm12>
                                    <v-text-field v-model="fechaBaja" label="Fecha Baja" dateformat="d M y" type="date" box></v-text-field>
                               </v-flex>

                              <v-flex xs12 sm12 md12 lg12 xl12>
                                <v-select v-model="vincu"
                                    :items="vinculado" label="Vinculado" box>
                                </v-select>
                              </v-flex>
                                
                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                    </div>
                                </v-flex>
                            
                                </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" slot="activator" flat @click.native="guardar">Guardar</v-btn>                              
                            </v-card-actions>
                        </v-card>
                    </v-dialog>               
                    
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==1">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==2">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==1">Activar </span>
                                <span v-if="adAccion==2">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>                    
                </v-toolbar>
            
            <v-data-table
                :headers="headers"
                :items="cliente"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.baja">
                            <v-icon
                            small
                            @click="activarDesactivarMostrar(2,props.item)"
                            >
                            block
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            small
                            @click="activarDesactivarMostrar(1,props.item)"
                            >
                            check
                            </v-icon>
                        </template>
                    </td>
                    <td>{{ props.item.codigo }}</td>
                    <td>{{ props.item.descripcion }}</td>
                    <td>{{ props.item.fechaAlta }}</td>
                    <td>{{ props.item.fechaBaja }}</td>
                     <td>
                        <div v-if="props.item.vinculadoPirineos">
                            <span class="blue--text">Si</span>
                        </div>
                        <div v-else>
                            <span class="red--text">No</span>
                        </div>
                    </td>
                    <td>
                        <div v-if="props.item.baja">
                            <span class="blue--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="red--text">Inactivo</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import Vue from 'vue';
   // import {tienePirineos} from '@/mixins.js'

    export default {
        data(){
            return {
                cliente:[],               
                vinculado:[],  
                dialog: false,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Codigo', value: 'codigo'},
                    { text: 'Descripcion', value: 'descripcion', sortable: false  },                
                    { text: 'Fecha Alta', value: 'fechaAlta', sortable: false  },  
                    { text: 'Fecha Baja', value: 'fechaBaja', sortable: false  },  
                    { text: 'Vinculado', value: 'vinculadoPirineos', sortable: false  },  
                    { text: 'Baja', value: 'baja', sortable: false  },  
                ],
                search: '',
                editedIndex: -1,
                codigo: '',
                descripcion: '',
                fechaAlta: '',
                fechaBaja: '',
                baja:'',
                vinculadoPirineos:'',
                valida: 0,
                validaMensaje:[],
                bolExiste:false,
                EstoyEditando:false,
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: '',
                pagination: {rowsPerPage: 12},   
                tienePirineos:false,     
                vinculado:["Si","No"],   
                vincu:'',
                codigocliente:'',    
            }
        },

        //mixins:[tienePirineos],        

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuevo Cliente OClock' : 'Actualizar Cliente OClock'
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            //this.Pirineos();
        },
        methods:{
            listar(){
                let me=this;
                axios.get('api/ClienteOClocks/Listar').then(function(response){
                me.cliente=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
            

            editItem (item) {
                let me = this;
                this.codigo=item.codigo;
                this.descripcion=item.descripcion;
                this.fechaAlta=moment(item.fechaAlta).format('YYYY-MM-DD');
                this.fechaBaja=moment(item.fechaBaja).format('YYYY-MM-DD');
                this.vinculadoPirineos=item.vinculadoPirineos;
                
                if(this.vinculadoPirineos==true)
                {
                    me.vincu = "Si"
                }
                else
                {
                    me.vincu="No";
                }
                
                this.editedIndex=-1;
                this.editedIndex=1;

                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
                this.codigo="";
                this.descripcion="";
                this.fechaAlta="";
                this.fechaBaja="";
                this.vinculadoPirineos="";
                this.editedIndex=-1;
                this.validaMensaje="";
            },

            guardar () {
                let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;

                if (this.validar()){
                    return;
                }


                    axios.post('api/ClienteOclocks/ExisteClienteOClock',{'descripcion': me.descripcion})                                
                    .then((response) => {      
                         console.log(response.data);                               
                        if(this.editedIndex > -1){//La data devuelve true si la empresa ya existe
                             this.EstoyEditando=true;                         
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data; 
                           Vue . swal ("La empresa: "+ this.descripcion + " ya esta registrada");                             
                        }

                        if(this.EstoyEditando==true){                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar
                                let me=this;

                                if(me.vincu=="Si")
                                {
                                   me.vinculadoPirineos=true;
                                }
                                else
                                {
                                    me.vinculadoPirineos=false;
                                }   


                                axios.post('api/ClienteOclocks/Actualizar',{
                                     'codigo':me.codigo,
                                     'descripcion': me.descripcion,
                                     'fechaAlta': me.fechaAlta,
                                     'fechaBaja': me.fechaBaja,
                                     'vinculadoPirineos': me.vinculadoPirineos,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                    
                            if (this.EstoyEditando==false && this.bolExiste==false){
                                //Código para guardar
                                let me=this;
                                me.fechaBaja="1999/01/31";  
                                
                                if(me.vincu=="Si")
                                {
                                   me.vinculadoPirineos=true;
                                }
                                else
                                {
                                    me.vinculadoPirineos=false;
                                }   

                                axios.post('api/ClienteOclocks/Crear',{
                                     'descripcion': me.descripcion,
                                     'fechaAlta': me.fechaAlta,
                                     'fechaBaja': me.fechaBaja,
                                     'vinculadoPirineos': me.vinculadoPirineos,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                    })
                        .catch((response) => {
                        console.log(response);
                    })
                    .then(() => {

                     });
            },

            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.descripcion.length<3 || this.descripcion.length>50){
                    this.validaMensaje.push("La descripción debe tener más de 3 caracteres y menos de 50 caracteres");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adId=item.codigo; 
                this.adNombre=item.descripcion;               
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                axios.post('api/ClienteOclocks/Activar',{
                  'codigo':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";                    
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });

            },
            desactivar(){
                let me=this;
                  axios.post('api/ClienteOclocks/Desactivar',{
                  'codigo':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                     me.listar();                     
                }).catch(function(error){
                    console.log(error);
                });
            }, 
            
        }        
    }
</script>

<style>


</style>
