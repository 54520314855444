<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                
                <v-toolbar-title>Notificaciones Enviadas</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>            
                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-on:change="onchange()"  v-model="codpersonal"
                                                :items="combopersonal"
                                                filled
                                                label="Personal" box>
                                            </v-select>
                                        </v-flex> 
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="notipush"
                :search="search"
                class="elevation-1"
                 :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">                    
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.fechaEnvio }}</td>
                    <td>{{ props.item.horaEnvio }}</td>
                    <td>{{ props.item.mensajeEnvio }}</td>
                    
                </template>               
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import Vue from 'vue';
    export default {
        data(){
            return {
                notipush:[],  
                combopersonal:[],                      
                dialog: false,
                headers: [
                   { text: 'Nombre', value: 'nombre' },                        
                   { text: 'Fecha Envio', value: 'fechaEnvio' },                 
                   { text: 'Hora Envio', value: 'horaEnvio' },  
                   { text: 'Notificación', value: 'mensajeEnvio' },    
                ],
                search: '',
                fechaInicio: '',
                fechaFin: '',      
                codpersonal:0,    
                pagination: {rowsPerPage: 12},  
            }
        },
        computed: {
        },

        watch: {
        },

        created () {
             this.selectPersonal();
             this.listar();

        },
        methods:{
            listar(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;

                axios.post('api/EnviarMensajesOneSignal/Listar', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){

                    me.notipush = response.data;

                })
                .catch(function(error){
                    console.log(error);
                });
            },

            close () {
                this.dialog = false;
            },

            filtrarfechas(){                
                let me=this;
                me.IdClienteOclock = this.$store.state.usuario.idclienteoclock

                axios.post('api/EnviarMensajesOneSignal/PushEnviados',{
                    'fecha':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.capturaCodPersonal,
                    'idclienteoclock':me.IdClienteOclock
                }).then(function(response){
                    me.notipush=response.data;
                    console.log("Se han cargados los datos");
                    me.close();    
                    
                    if(me.push3=="")
                    {
                        Vue . swal ("!!! No hay datos que mostrar !!!");
                    }
                                            
                }).catch(function(error){
                    console.log(error);
                });
            },

             selectPersonal(){
                let me=this;
                var personalArray=[];
                me.codPersonal = this.$store.state.usuario.id,
                me.IdClienteOclock = this.$store.state.usuario.idclienteoclock

                axios.post('api/Notificaciones/SelectPersonal',{
                 'Personal':me.codPersonal,
                 'idclienteoclock':me.IdClienteOclock
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.combopersonal.push({text: x.nombre,value:x.personal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            },

            limpiar(){
               this.fechaInicio="";
               this.fechaFin="";
               //this.codPersonal="";
            },

             onchange: function() {
                 let me = this;   
                  me.capturaCodPersonal = this.codpersonal; 
             },

        }        
    }
</script>
