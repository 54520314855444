<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>

                <v-toolbar-title>Empresas</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" :disabled="booltienePirineos">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Nuevo</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm12 md12>
                                    <v-text-field disabled v-model="id" label="Código" box></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="descripcion" label="Empresa" box></v-text-field>
                                </v-flex>

                              <v-flex xs12 sm12 md12 >
                                    <v-select v-on:change="onchangeProvincia()" v-model="idpais"
                                        :items="paises"
                                        label="Países" box>
                                    </v-select>
                                 </v-flex>

                                <v-flex xs12 sm12 md12 >
                                    <v-select v-on:change="onchangePoblacion()" v-model="idprovincia"
                                    :items="comboProvincias" 
                                     label="Provincias" box>
                                    </v-select>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-select v-model="idpoblacion"
                                    :items="comboPoblacion" 
                                     label="Municipio" box>
                                </v-select>
                                </v-flex>     

                                <v-flex xs12 sm12 md12  v-if=boolCliente>
                                    <v-select  v-model="idcliente"
                                    :items="comboCliente" label="Cliente Oclock" box>
                                    </v-select>
                                  </v-flex>                     

                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                    </div>
                                </v-flex>
                            
                                </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="guardar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==2">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==1">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==2">Activar </span>
                                <span v-if="adAccion==1">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="empresas"
                :search="search"
                class="elevation-1"
                 :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                >

                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                            small
                            v-if=!booltienePirineos
                            class="mr-2"
                            @click="editItem(props.item)"

                            >edit
                            
                        </v-icon>
                            

                        <template v-if="props.item.baja">
                            <v-icon
                            small
                            v-if=!booltienePirineos
                            @click="activarDesactivarMostrar(2,props.item)"

                            >
                            block
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            small
                            v-if=!booltienePirineos
                            @click="activarDesactivarMostrar(1,props.item)"

                            >
                            check
                            </v-icon>
                        </template>
                    </td>
                    <td style="display: none">{{ props.item.id }}</td>
                    <td style="display: none">{{ props.item.idPais}}</td>
                    <td style="display: none">{{ props.item.idProvincia}}</td>
                    <td style="display: none">{{ props.item.idPoblacion }}</td>
                    <td>{{ props.item.descripcion }}</td>
                    <td>{{ props.item.nomPais }}</td>
                    <td>{{ props.item.nomProvincia }}</td>
                    <td>{{ props.item.nomPoblacion }}</td>
                   <td style="display: none">{{ props.item.idClienteOclock }}</td>
                    <td>
                        <div v-if="props.item.baja">
                            <span class="red--text">Inactivo</span>
                        </div>
                        <div v-else>
                            <span class="blue--text">Activo</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';


    export default {
        data(){
            return {
                empresas:[], 
                ComboPaises:[],   
                paises:[], 
                comboProvincias:[],    
                comboPoblacion:[],    
                comboCliente:[],          
                dialog: false,
                 headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Empresa', value: 'descripcion' },
                    { text: 'País', value: 'nomPais' },
                    { text: 'Provincia', value: 'nomProvincia' },
                    { text: 'Municipio', value: 'nomPoblacion' },
                    { text: 'Estado', value: 'baja', sortable: false  }                
                ],
                search: '',
                editedIndex: -1,
                id: '',
                nomprovincia:'',
                nompais:'',
                idpais:'',
                idprovincia:'',
                idclienteoclock:'',
                idpoblacion:'',
                descripcion:'',
                nompoblacion:'',
                valida: 0,
                validaMensaje:[],  
                bolExiste:false,
                EstoyEditando:false,
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: '',
                capturaCodPais:'',   
                capturaCodProvincia:'',
                booltienePirineos:false,  
                idcliente:'',
                codigocliente:'',
                boolCliente:'' ,
                pagination: {rowsPerPage: 12},                        
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nueva Empresa' : 'Actualizar Empresa'
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            this.selectPaises();
            this.pirineos();
            this.selectCliente();
        },
        methods:{
            listar(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Empresas/Listar', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){
                    //Verifica si el usuario es Master.
                     axios.post('api/ClienteOclocks/OcultarCliente',{
                        'codigo':me.codigocliente  
                        }).then(function(response){
                            me.boolCliente=response.data;
                        }).catch(function(error){
                            console.log(error);
                        }); 
                    ///
                    me.empresas=response.data;                    
                })
                .catch(function(error){
                    console.log(error);
                });
            },

            Excel(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;

                axios.post('api/Empresas/Excel', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){
                    //me.empresas=response.data;    
                     Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");             
                                  
                })
                .catch(function(error){
                    console.log(error);
                });
            },
            
            editItem (item) {
                this.id=item.id;
                this.idpais=item.idPais;
                this.idprovincia=item.idProvincia;
                this.idpoblacion = item.idPoblacion;
                this.descripcion=item.descripcion;
                this.editedIndex=1;
                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
                this.nombre="";
                this.descripcion="";
                this.idpais="";
                this.idprovincia="";
                this.idpoblacion="";
                this.editedIndex=-1;
                this.validaMensaje="";
            },

            guardar () {
                let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;

                if (this.validar()){
                    return;
                }
                    axios.post('api/Empresas/ExisteEmpresa',{
                        'descripcion': me.descripcion,
                        'idclienteoclock':this.$store.state.usuario.idclienteoclock   
                        })                         
                    .then((response) => {                         
                        if(this.editedIndex > -1){//La data devuelve true si la empresa ya existe
                             this.EstoyEditando=true;    
                             console.log("He entrado en edicion: ");                     
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data;     
                           Vue . swal ("La empresa: "+ this.descripcion + " ya está registrada");                       
                        }

                        if(this.EstoyEditando==true){                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar
                                let me=this;
                                axios.post('api/Empresas/Actualizar',{
                                     'id':me.id,
                                     'idpais':me.idpais,
                                     'idprovincia':me.idprovincia,
                                     'idpoblacion':me.idpoblacion,
                                     'Descripcion': me.descripcion,
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                    
                            if (this.EstoyEditando==false && this.bolExiste==false){
                                if(this.$store.state.usuario.rol=="Ecomputer")
                                 {      
                                    this.codigocliente = this.idcliente;
                                 }
                                 else
                                 {
                                    this.codigocliente = this.$store.state.usuario.idclienteoclock;
                                 }
                                //Código para guardar
                                let me=this;                                                            
                                axios.post('api/Empresas/Crear',{
                                     'idpais':me.idpais,
                                     'idprovincia':me.idprovincia,
                                     'idpoblacion':me.idpoblacion,
                                     'Descripcion': me.descripcion,
                                     'idclienteoclock': me.codigocliente  
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                    })
                        .catch((response) => {
                        console.log(response);
                    })
                    .then(() => {

                     });
            },

            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.idPais==0 || this.idProvincia==0 || this.idPoblacion==0 || this.descripcion.length<0) {
                    this.validaMensaje.push("Falta datos por seleccionar ");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.descripcion;
                this.adId=item.id;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                axios.post('api/Empresas/Activar',{
                  'id':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(){
                let me=this;
                axios.post('api/Empresas/Desactivar',{
                  'id':me.adId,
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            
             selectPaises(){
                let me=this;
                var paisesArray=[];
                axios.post('api/Paises/SelectPaises',{
                'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    paisesArray=response.data;
                    paisesArray.map(function(x){
                    me.paises.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            }, 
            
            selectProvincias(){                                
                let me=this;
                if(me.capturaCodPais!="")
                {
                    var provinciaArray=[];
                    axios.post('api/Provincias/SelectProvincia',{
                    'IdPais':me.capturaCodPais,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                    }).then(function(response){
                        provinciaArray=response.data;
                        provinciaArray.map(function(x){
                        me.comboProvincias.push({text: x.descripcion,value:x.id});
                        }); 
                        }).catch(function(error){
                            console.log(error);
                        });  
                }                
            }, 

               selectPoblacion(){                                
                let me=this;
                if(me.capturaCodProvincia!="")
                {
                     var PoblacionArray=[];
                    axios.post('api/Poblaciones/SelectPoblacion',{
                    'idProvincia':me.capturaCodProvincia,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                    }).then(function(response){
                        PoblacionArray=response.data;
                        PoblacionArray.map(function(x){
                        me.comboPoblacion.push({text: x.nomPoblacion,value:x.id});
                        }); 
                        }).catch(function(error){
                            console.log(error);
                        });  
                    }  
                },
                
                selectCliente(){
                let me=this;
                var clienteArray=[];
                axios.get('api/ClienteOclocks/SelectCliente')
                .then(function(response){
                    clienteArray=response.data;
                    clienteArray.map(function(x){
                    me.comboCliente.push({text: x.descripcion,value:x.codigo});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
 
            onchangeProvincia: function() {
            this.capturaCodPais = this.idpais;
            this.comboProvincias =[];
            this.comboPoblacion=[];
            this.selectProvincias();  
            },

            onchangePoblacion: function() {
            this.capturaCodProvincia = this.idprovincia;
            this.comboPoblacion =[];
            this.selectPoblacion();
            },

            crearPDF(){
                var columns = [
                    {title: "Empresa", dataKey: "descripcion"},
                    {title: "País", dataKey: "nomPais"}, 
                    {title: "Provincia", dataKey: "nomProvincia"}, 
                    {title: "Municipio", dataKey: "nomPoblacion"},
                    {title: "Estado", dataKey: "baja"}
                ];
                var rows = [];

                this.empresas.map(function(x){
                    rows.push({descripcion:x.descripcion,nomPais:x.nomPais,nomProvincia:x.nomProvincia,nomPoblacion:x.nomPoblacion,baja:x.baja?"Inactivo":"Activo"});                    
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Empresa", 40, 30);
                    }
                });
                doc.save('Empresa.pdf');
            },

           pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },

           


        }        
    }
</script>
