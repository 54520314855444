
<template>
  <div id="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import axios from 'axios';
import AxiosPlugin from 'vue-axios-cors';

var lat = 0.0;
var long = 0.0;
export default {
  name: "LeafletMap",
  props:[],
  markers:['todo'],
  data() {
    return {
        map: null,
        icon: L.icon({
        iconSize: [38, 95],
        iconAnchor: [22, 94],
        customIcon:'',
       // lat:'',
       // long:''
      })
    };
  },

  mounted() {    
    let me = this;

      axios.post('api/Sesiones/MarkerProvince',{
      'idClienteOclock':this.$store.state.usuario.idclienteoclock,
      }).then(function(response){   
        me.lat = response.data.latProvince;
        me.long= response.data.longProvince;
        console.log("Latitud: "+me.lat,"Longitud: "+me.long);
      
      }).catch(function(error){
      console.log(error);
      });

    me.map = L.map("mapContainer").setView([41.635974884033,  -0.9018229842186], 12);
    me.customIcon = new L.Icon({
      //iconUrl: 'https://image.flaticon.com/icons/svg/854/854866.svg',
      iconUrl: 'https://img.icons8.com/color/48/000000/city-guide.png',
      iconSize: [40, 40],
      iconAnchor: [15, 40],
    });

    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '<a</a>'
    }).addTo(this.map);
  },  

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

   created () {
    this.markerEntrada();
   },
        
   methods:{
  
     markerEntrada(){
      let me = this;
      

      axios.post('api/Sesiones/MarkerEntrada',{
      'idClienteOclock':this.$store.state.usuario.idclienteoclock,
      'idPersonal':this.$store.state.usuario.id
      }).then(function(response){   
        console.log(response.data);

       response.data.forEach(function(item){
       
       var marker = new L.marker([item.latitud,item.longitud], {icon: me.customIcon}, { opacity: 0.01 });
       marker.bindTooltip(item.name, { permanent: true, direction:'right',offset: [0, 12] });
       marker.bindPopup(item.datas);
       marker.on('mouseover', function (e) {
            this.openPopup();
        });
        marker.on('mouseout', function (e) {
            this.closePopup();
        });

       marker.addTo(me.map);
              
      })//Cierra el for
      }).catch(function(error){
      console.log(error);
      });
    },


  },
};

</script>
<style scoped>
#mapContainer {
  width: 100vw;
  height: 100vh;
}
</style> 