<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
                <v-toolbar-title>Informe Fichajes</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>            
                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-on:change="onchange()" v-model="codPersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal" box>
                                            </v-select>
                                        </v-flex>   

                                       
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="reportemensual"
                :search="search"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                    <td style="display: none">>{{ props.item.codpersonal}}</td>
                    <td>{{ props.item.fecha | filterDate }}</td>
                    <td>{{ props.item.fecha2}}</td>
                    <td style="display: none">{{ props.item.nomEmpresa}}</td>
                    <td style="display: none">{{ props.item.nombre }}</td>
                    <td style="display: none">{{ props.item.tipo }}</td>
                </template>


                
                
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import moment from "moment";
    import Vue from 'vue';


    export default {
        //Este filtro es util para darle formato a un valor y meterlo en la tabla
        filters: {
            filterDate: function (value) {
                return moment(value).format('DD/MM/YYYY')
            },

            minutos: function (value) {
                return this.minutosTrabajados();
            }

        },
        data(){
            return {
                usuarios:[], 
                reportemensual:[],                
                dialog: false,
                headers: [
                    // { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Fecha', value: 'fecha' },      
                    { text: 'Hora', value: 'fecha2' },                                                             

                ],
               search: '',
                editedIndex: -1,
                id: '',
                idrol:'',
                idfichaje:'',
                tipo:'',
                fecha:'',
                nombre:'',
                apellido1:'',
                apellido2:'',
                fechaInicio: '',
                fechaFin: '',
                pagination: {rowsPerPage: 12},
                codPersonal:0,
                capturaEmpresa:'',

            }
        },
       
        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.selectPersonal();   
        },
        methods:{
                        crearPDF(){
                var columns = [
                    {title: "Fecha ", dataKey: "fecha"}, 
                    {title: "Hora ", dataKey: "fecha2"}, 
                ];
 

                var rows = [];
                let me = this;
                
               
                this.reportemensual.map(function(x){
                    rows.push({fecha:moment(x.fecha).format("DD/MM/YYYY"),fecha2:x.fecha2,nombre:x.nombre,tipo:x.tipo});                    
                });
                  // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 100},
                    addPageContent: function(data) {
                        doc.text("Informe de Fichaje :   " +moment(me.capturaFechaIncicio ).format('DD/MM/YYYY')+" hasta "+moment(me.capturaFechaFin).format('DD/MM/YYYY'), 40, 30);
                        
                        doc.setFontSize(12);
                        doc.setTextColor(40);
                        doc.setFontStyle('normal');
                        doc.text("Nombre: :  "+ me.capturaNombre,40,60);
                        doc.text("Empresa: :  "+ me.capturaEmpresa,40,80);
                        

                    }
                });
                doc.save('Informe_Fichajes.pdf');
            },

            
            close() {
                this.dialog = false;
            },



            footer(){ 
                doc.text(150,285, 'page ' + doc.page); //print number bottom right
                doc.page ++;
            },

             limpiar()
            {
                this.fechaInicio="";
                this.fechaFin="";
                this.codPersonal="";
            },


           
            filtrarfechas(){
                
                let me=this;

                axios.post('api/Informes/InformeFichajes',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.capturaCodPersonal,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.reportemensual=response.data;

                    if(me.reportemensual=="")
                    {
                        Vue . swal ("!!! No hay datos que mostrar !!!"); 
                    }
                    else
                    {
                      me.reportemensual=response.data;
                      me.capturaEmpresa = me.reportemensual[0].nomEmpresa;
                      me.capturaNombre = me.reportemensual[0].nombre;
                      me.capturaFechaIncicio=me.fechaInicio;
                      me.capturaFechaFin=me.fechaFin;
                      me.close();
                      me.limpiar();   
                    }
                   
                                        
                }).catch(function(error){
                    console.log(error);
                });
            },

            selectPersonal(){
                let me=this;
                var personalArray=[];
                me.codPersonal = this.$store.state.usuario.id;
                axios.post('api/Notificaciones/SelectPersonal',{
                 'Personal':me.codPersonal,
                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.usuarios.push({text: x.nombre,value:x.personal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            },

            onchange: function() {
                 let me = this;   
                  me.capturaCodPersonal = this.codPersonal; 
             },
            

        }        
    }
</script>