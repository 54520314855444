<template>
  <div class="cajageneral">
    <div>
      <h1 class="titulo">Fichaje</h1>
      <div class="caja1">
        <h3 style="font-size: 50px; color:#A6B9BD " v-text="currentTime"></h3>
        <h3 style="font-size: 30px; margin-left: 30px;" v-text="currentDate"></h3>
      </div>
      <div class="caja2">
        <v-layout>          
          <v-flex > 
            <v-data-table
                    :headers="headers"
                    :items="fichajes"
                    hide-actions
                    class="elevation-1"
                    :rows-per-page-items="[5,10, 20, 30, 40]"
                    :pagination.sync="pagination"
                    style="width: 500px; margin-left: 30px">
                    <template slot="items" slot-scope="props">
                        <td style="display: none" class="text-xs-left">{{ props.item.id }}</td>
                        <td style="display: none" class="text-xs-left">{{ props.item.personal }}</td>
                        <td class="text-xs-left">{{ props.item.fecha }}</td>
                        <td class="text-xs-left">{{ props.item.hora }}</td>
                    </template>
                </v-data-table>
          </v-flex>
        </v-layout>
      </div>

      <div style="max-width: 800px;">
          <div>
            <button class="boton" @click="Entrada">ENTRADA</button>
          </div>

          <div>
            <div> 
              <button
                  style="background-color: #17438F; width: 100%"
                  type="button"
                  class="boton"
                  @click="Salida"
                >SALIDA</button>
            </div>
          </div>
            
          <v-dialog v-model="dialog" style="display: flex; flex: 1;">
            <button
              slot="activator" 
              id="btnentrada"
              type="button"
              class="boton"
              >INCIDENCIA
            </button>
            <v-card>
              <v-card-title>
                  <span class="headline" >Crear Incidencia</span>
                      </v-card-title>
                            <v-card-text>
                            <v-container grid-list-md>
                            <v-layout wrap>

                              <v-flex xs12 sm12 md12>
                                    <v-select v-model="id"
                                    :items="causas" label="Causas" box>
                                    </v-select>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="Observacionesinci" label="Observaciones" box></v-text-field>
                                </v-flex>  
                          </v-layout>
                            </v-container>
                            </v-card-text>  
                            
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="Incidencia">Crear</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog> 

                    </div>           
        </div>
      </div>                                                 
  
</template>


<!--<script src="https://maps.google.com/maps/api/js?sensor=false"></script>-->

<script type="text/javascript">

import moment, { now } from "moment";
import axios from "axios";
import Vue from 'vue';

var center= {latt: 0, long: 0};
var CodPersonal = "";
var Fecha= null;
var TiempoPresente= null;
var Longitud= null;
var Latitud= null;
var existeEntrada = null;
var existeIncidencia = null;
var existeSalida = null;


function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    x.innerHTML = "Geolocation is not supported by this browser.";
  }
}

function showPosition(pos) {
  center['latt'] = pos.coords.latitude;
  center['long'] = pos.coords.longitude;

  Latitud = pos.coords.latitude;
  Longitud = pos.coords.longitude;

}

function ActualizarDatos (){
  
      var today = new Date();
      var datetime = today.getFullYear()+'-'
      +(today.getMonth()+1)+'-'
      +today.getDate()+' '+today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      Fecha = datetime;
}

export default {
  data() {
    return {
      fichajes:[],
      headers: [
                    { text: 'Fecha Fichaje', value: 'fecha', sortable: false },
                    { text: 'Hora Fichaje', value: 'fechaEntrada', sortable: false },
                   // { text: 'Tipo Incidencia', value: 'tipoIncidencia',sortable: false }                 
                ],
      dialog: false,
      dialog2:false,
      Observacionesinci: '',
      cerrarIncidencia:'',
      message: "Current Time:",
      currentTime: null,
      latitud: 10,
      longitud: 10,
      mapTypeId: "terrain",
      markers: [
       { position: {lat: 123, lng: 123} }
      ],
      rowsPerPageItems: [2,4],
      pagination: {
      rowsPerPage: 2
    },
    id: '',
    recibeEntrada:'',
    tiposIncidencias: ['Personales','Laborales'], 
    fechaInicio:'',
    fechaFin:'',
    Personal:'',
    capturaId:'',
    capturaPersonal:'',
    idclienteoclock:'',
    causas:[], 
    }
  },
created() {

    this.currentDate = moment().format("DD/MM/YYYY");
    this.currentTime = moment().format("LTS");    
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
    getLocation();

    this.listar();
    this.selectCausa();
    
},

  methods: {
    updateCurrentTime() {
      this.currentTime = moment().format("LTS");
    },

    //Entrada: Para Fichar en la Empresa
    Entrada() {
      let me = this;

       me.Personal = this.$store.state.usuario.id; 
       me.idclienteoclock = this.$store.state.usuario.idclienteoclock;

       var today = new Date();
      var datetime = today.getFullYear()+'-'
      +(today.getMonth()+1)+'-'
      +today.getDate()+' '+today.getHours() + ":" + today.getMinutes();
      me.Fecha = datetime;

      //Primero preguntamos si existe una Incidencia
      axios.post("api/Horario/ExisteIncidencia", {
        'Personal':me.Personal,
        'idclienteoclock':me.idclienteoclock
        })
        .then(function(response) {
          me.existeIncidencia=response.data;                     
          
          //Segundo preguntamos si existe una Entrada
          axios.post("api/Horario/ExisteEntrada", {
          'Personal':me.Personal,
          'idclienteoclock':me.idclienteoclock 
          })
           .then(function(response) {
            me.existeEntrada=response.data;              
            
            if (me.existeIncidencia ) 
            {
            Vue . swal ("!!! Cierre la Incidencia antes de Fichar(Entrada) !!!");
            }
            else
            {
              if (me.existeEntrada) 
              {
                Vue . swal ( '!!! Ya existe una entrada cierrela manualmente !!!' ) ;
              }
              else
              {
                if (window.confirm("!!! Está seguro que desea Fichar  !!!")) 
                {
                    axios.post("api/Horario/CrearFichaje", 
                    {
                      'Personal':me.Personal,
                      'fecha':me.Fecha,
                      'horaEntrada': me.Fecha,     
                      'latitud':Latitud,
                      'longitud': Longitud,  
                      'idclienteoclock':me.idclienteoclock
                        })
                          .then(function(response) {
                          me.listar();
                          Vue . swal ("!!! Has Fichado en la Empresa !!!");
                          me.existeEntrada = true;
                          })
                          .catch(function(error) {
                          console.log(error);
                          }); 
                    }                     
                  } 
                }
          })
           .catch(function(error) {
            console.log(error);
           });     
        })
          .catch(function(error) {
          console.log(error);
        });           
    },

      //Salida: Salida de la Empresa
    Salida() {
      let me = this;      

       var today = new Date();
      var datetime = today.getFullYear()+'-'
      +(today.getMonth()+1)+'-'
      +today.getDate()+' '+today.getHours() + ":" + today.getMinutes();
      me.Fecha = datetime;

       me.Personal = this.$store.state.usuario.id; 
       me.idclienteoclock = this.$store.state.usuario.idclienteoclock;

      axios.post("api/Horario/ExisteEntrada", {//Verifica que exista una entrada
          'Personal':me.Personal,
          'idclienteoclock':me.idclienteoclock
        })
        .then(function(response) {
          me.existeEntrada=response.data;  
            
              axios.post("api/Horario/ExisteIncidencia", {//Verifica que exista una Salida
              'Personal':me.Personal,
              'idclienteoclock':me.idclienteoclock
              })
              .then(function(response) {
                me.existeIncidencia=response.data;   
                
                  if(!me.existeEntrada && !me.existeIncidencia)
                  {
                    alert("!!! No existen entradas que cerrar !!!");
                  }
                  else
                  {
                    if(me.existeIncidencia)
              {
                 if (window.confirm("!!! Está seguro que desea salir de la Incidencia  !!!")) 
                {
                  //Me da el último Id de la Incidencia
                  axios.post("api/Horario/IdIncidencia", 
                  {
                    'Personal':me.Personal,
                    'idclienteoclock':me.idclienteoclock
                    })
                    .then(function(response) {
                      me.capturaId=response.data;   
                      
                       axios.post("api/Horario/SalirIncidencia", {
                        'id':me.capturaId,
                        'Personal':me.Personal,
                        'fechaSalida':me.Fecha,
                        'horaSalida': me.Fecha,     
                        'latitud':Latitud,
                        'longitud': Longitud,  
                        'idclienteoclock':me.idclienteoclock
                      })
                      .then(function(response) {
                        me.listar();
                        me.close();
                        me.limpiar();
                        alert("!!! Ha salido de la incidencia !!!");
                        me.existeIncidencia=false; 
                      })
                      .catch(function(error) {
                        console.log(error);
                      });  

                      
                    })
                    .catch(function(error) {
                      console.log(error);
                    });        
                  }                   
                }                 
                else
                {
                    if (window.confirm("!!! Está seguro que desea salir de la empresa  !!!")) 
                    {
                      axios.post("api/Horario/IdEntrada", {
                        'Personal':me.Personal,
                        'idclienteoclock':me.idclienteoclock
                      })
                      .then(function(response) {
                        me.capturaId=response.data;  
                        
                        axios.post("api/Horario/SalirEntrada", 
                        {
                          'id':me.capturaId,
                          'Personal':me.Personal,
                          'fechaSalida':me.Fecha,
                          'horaSalida': me.Fecha,     
                          'latitud':Latitud,
                          'longitud': Longitud,  
                          'idclienteoclock':me.idclienteoclock
                          })
                          .then(function(response) {
                            me.listar();
                            me.close();
                            me.limpiar();
                              alert("!!! Ha salido correctamente !!!");
                                me.existeSalida=response.data;  
                                me.existeEntrada=false; 
                            })
                              .catch(function(error) {
                              console.log(error);
                          });
                            
                          })
                          .catch(function(error) {
                            console.log(error);
                          });             
                      }
                  }
                }              
              })
              .catch(function(error) {
                console.log(error);
              });         

                  
            })
            .catch(function(error) {
              console.log(error);
            });             
    },

      selectCausa(){
          let me=this;
          var causasArray=[];
          axios.post('api/Causas/SelectCausa' ,{
            'idclienteoclock':me.idclienteoclock
          }).then(function(response){
          causasArray=response.data;
          causasArray.map(function(x){
          me.causas.push({text: x.descripcion,value:x.id});
          console.log(me.causas);
          });
          }).catch(function(error){
             console.log(error);
          });
      }, 

      Incidencia() {
      let me = this;

       me.Personal = this.$store.state.usuario.id; 
       me.idclienteoclock = this.$store.state.usuario.idclienteoclock;

       var today = new Date();
      var datetime = today.getFullYear()+'-'
      +(today.getMonth()+1)+'-'
      +today.getDate()+' '+today.getHours() + ":" + today.getMinutes();
      me.Fecha = datetime;

      axios.post("api/Horario/ExisteIncidencia", {
        'Personal':me.Personal,
        'idclienteoclock':me.idclienteoclock
        })
        .then(function(response) {
          me.existeIncidencia=response.data;  

          if(me.existeIncidencia)
          {
            Vue . swal ("!!! Cierre la Incidencia antes de crear otra !!!");
          }
          else
          {
           if (window.confirm("!!! Está seguro que desea crear la Incidencia  !!!")) 
           {
              axios.post("api/Horario/CrearIncidencia", 
              {
                'Personal':me.Personal,
                'fecha':me.Fecha,
                'horaEntrada': me.Fecha,  
                'incidencia':me.id,   
                'latitud':Latitud,
                'longitud': Longitud,  
                'observaciones':me.Observacionesinci,
                'idclienteoclock':me.idclienteoclock
                  })
                    .then(function(response) {
                      me.listar();
                      me.close();
                      me.limpiar();
                      Vue . swal ("!!! Incidencia Enviada correctamente !!!");
                          me.recibeEntrada = false;
                          me.existeIncidencia=true;
                    })
                    .catch(function(error) {
                    console.log(error);
                    }); 
              }  
            }    
            })
            .catch(function(error) {
              console.log(error);
            });        
      },
     limpiar(){
                this.id="";
                this.Observacionesinci="";
                this.fecha="";
                this.hora="";
            },

      limpiar2(){
                this.Observacionesinci="";
                this.fechaInicio="";
                this.fechaFin="";
            },

  
    selectCausa (){
                let me=this;
                var causaArray=[];
                axios.post('api/Causas/SelectCausa',{
                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    causaArray=response.data;
                    causaArray.map(function(x){
                    me.causas.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
    },

    listar(){ 
                ActualizarDatos();     
                let me=this;
                axios.post('api/Horario/HorarioPorDia',{
                'Personal': this.$store.state.usuario.id,
                'Fecha':  me.Fecha,
                'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    //console.log(response);
                    me.fichajes=response.data;
                    console.log("Oclock: "+me.idclienteoclock);
                }).catch(function(error){
                    console.log(error);
                });
            },

    close () {
     this.dialog = false;
     this.limpiar();
    },

    close2 () {
     this.dialog2 = false;
     this.limpiar2();
    },

    botones (){
      let me = this;
      axios.get('api/Usuarios/ExisteEntrada').then(function(response){
          me.recibeEntrada = response.data;
          }).catch(function(error){
              console.log(error);
      });      
    },

     
  },
  crearmarcador (lati,long){
    this.markers[0]['position']['lat'] = lati;
    this.markers[0]['position']['lng'] = long;
  },

   
};
</script>

<style>

      .cajageneral {
        display: flex;
        margin-left: 50px;
        margin-bottom: 200px;
        justify-content: center;
        width: 100%;
      }
      .titulo {
        color: #2c4b9c;
        font-size: 40px;
        margin-bottom: 30px;
      }
      .caja1 {
        margin-bottom: 50px;
        border-radius: 20px;
        padding: 20px 70px;
        text-align: center;
      }

      .caja2 {
        margin-bottom: 50px;
        border-radius: 20px;
        padding: 20px 50px;
        display: flex;
        text-align: center;
      }

      .caja3 {
        display: flex;
        margin-left: 50px;
        margin-bottom: 200px;
        justify-content: center;
      }

      .boton {
        background-color: #60c3ff;
        border-radius: 6px;
        padding: 10px 30px;
        margin-top: 5px;
        color: White;
        text-align: center;
        width: 100%;
        border: none;
      }

      .mapa {
        width: 600px;
        height: 600px;
        background-color: darkgoldenrod;
      }

      .vue-map-container {
        height: 600px;
        max-width: 600px;
        width: 600px;
      }

      .time {
        font-size: 7em;
      }

      .shadow {
        text-shadow: 0 0 15px rgba(100, 100, 100, 0.35);
      }
      .v-dialog__activator {
        display: flex;
        flex: 1;
      }


</style>
