<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
                
                <v-toolbar-title>Informe Asistencia</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>            
                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-on:change="onchange()"  v-model="codpersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal" box>
                                            </v-select>
                                        </v-flex>     
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="reportemensual"
                :search="search"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                    <td style="display: none">>{{ props.item.personal}}</td>
                    <td>{{ props.item.fecha | filterDate }}</td>
                    <td style="display: none">{{ props.item.nombre }}</td>
                    <td style="display: none">{{ props.item.nomEmpresa }}</td>
                    <td style="display: none">{{ props.item.nomDepartamento }}</td>
                    <td  style="display: none">{{ props.item.minTrabajados }}</td>
                    <td  style="display: none">{{ props.item.minIncidencia }}</td>
                    <td  style="display: none">{{ props.item.totMinutos }}</td>
                    <td>{{ props.item.tiempoPresente }}</td>
                    <td>{{ props.item.tiempoIncidencia }}</td>
                    <td>{{ props.item.diferenciaMinutos }}</td>
                    <td style="display: none">{{ props.item.totalHoras }}</td>
                    <td style="display: none">{{ props.item.tipo }}</td>
                    <td style="display: none">{{ props.item.tipoIncidencia }}</td>
                </template>
                                
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>

    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import moment from "moment";
    import VueExcelXlsx from "vue-excel-xlsx";
    import XLSX from 'xlsx';
    import Vue from 'vue';


    export default {
        //Este filtro es util para darle formato a un valor y meterlo en la tabla
        filters: {
            filterDate: function (value) {
                //return moment(value).format('DD/MM/YYYY h:mm')
                return moment(value).format('DD/MM/YYYY')
            },

            minutos: function (value) {
                return this.minutosTrabajados();
            }

        },
        data(){
            
            return {            
                usuarios:[], 
                reportemensual:[],       
                crearExcel:[],         
                dialog: false,
                headers: [
                    { text: 'Fecha Entrada', value: 'fecha' },                        
                    { text: 'Minutos Trabajados', value: 'tiempoPresente' },
                    { text: 'Minutos Incidencia', value: 'tiempoIncidencia' },                   
                    { text: 'Diferencia Minutos', value: 'DiferenciaMinutos' },    
                ],
               search: '',
                editedIndex: -1,
                id: '',
                idrol:'',
                idfichaje:'',
                tipo:'',
                fecha:'',
                nombre:'',
                apellido1:'',
                apellido2:'',
                fechaInicio: '',
                fechaFin: '',
                fechaInicio1: '',
                fechaFin1: '',
                pagination: {rowsPerPage: 12},
                codPersonal:0,
                personal:0,
                diasTrabajados:'',
                minutosIncidencias:'',
                minutosPersonales:'',
                diferenciaMinutos:'',
                minutosSalidas:'',
                minutosFavorContraPersonal:'',
                horasTrabajadas:'',
                capturaFechaIncicio:'',
                capturaFechaFin:'',
                capturaCodPersonal:'',
                capturaNombre:'',
                capturaEmpresa:'',
                capturaDepartamento:'',
                urlExcel:'',
                codpersonal:'',
                totMinutos:'',
            }
        },
       
        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
             this.selectPersonal();

        },
       
        methods:{
            DiasTrabajados(){
                let me=this;
                me.codPersonal = me.capturaCodPersonal;
                axios.post('api/Informes/DiasTrabajados',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.diasTrabajados=response.data;                  
                }).catch(function(error){
                    console.log(error);
                });
            },

           /* HorasTrabajadas(){
                let me=this;
                axios.post('api/Informes/HorasTrabajados',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':capturaCodPersonal
                }).then(function(response){
                    me.horasTrabajadas=response.data;
                    me.close();
                    me.limpiar();                     
                }).catch(function(error){
                    console.log(error);
                });
            },


            MinutosTrabajados(){
                let me=this;
                axios.post('api/Informes/MinutosTrabajados',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal
                }).then(function(response){
                    me.minutosTrabajados=response.data;
                    me.close();
                    me.limpiar();                     
                }).catch(function(error){
                    console.log(error);
                });
            },

            MinutosIncidencias(){
                let me=this;
                axios.post('api/Informes/MinutosIncidencias',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal
                }).then(function(response){
                    me.minutosIncidencias=response.data;
                    me.close();
                    me.limpiar();                     
                }).catch(function(error){
                    console.log(error);
                });
            },

            MinutosPersonales(){
                let me=this;
                axios.post('api/Informes/MinutosPersonales',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal
                }).then(function(response){
                    me.minutosPersonales=response.data;
                    me.close();
                    me.limpiar();                     
                }).catch(function(error){
                    console.log(error);
                });
            },

            MinutosSalidas(){
                let me=this;
                axios.post('api/Informes/MinutosSalidas',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal
                }).then(function(response){
                    me.minutosSalidas=response.data;
                    me.close();
                    me.limpiar();                     
                }).catch(function(error){
                    console.log(error);
                });
            },

            MinutosFavorContra(){
                let me=this;
                axios.post('api/Informes/MinutosFavorContra',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal
                }).then(function(response){
                    me.minutosFavorContraPersonal=response.data;                
                }).catch(function(error){
                    console.log(error);
                });
            },*/

            crearPDF(){
                var columns = [
                    {title: "Fecha", dataKey: "fecha"},   
                   // {title: "Min Asignados", dataKey: "minutosTrabajados"},                     
                    {title: "Min Trabajados", dataKey: "tiempoPresente"},   
                    {title: "Min Incidencia", dataKey: "tiempoIncidencia"},         
                    //{title: "Horas Trabajadas", dataKey: "totalHoras"}, 
                    {title: "Dif Minutos", dataKey: "diferenciaMinutos"},
                ];
 

                var rows = [];
                let me = this;
                
                /*var restoMinutosIncidencia,horasLaborales,totalHorasMinutosLaborales;

                this.restoMinutosIncidencia= me.minutosIncidencias%60; //Me da el resto de la division, para sacar los minutos
                this.horasLaborales=Math.floor(me.minutosIncidencias/60).toFixed(0);//Saca solo la hora, o decimales
                this.totalHorasMinutosLaborales=this.horasLaborales+","+this.restoMinutosIncidencia;//Concatena hora y minutos

                var restoMinutosPersonal,horasPersonales,totalHorasMinutosPersonales;

                this.restoMinutosPersonal= me.minutosPersonales%60; //Me da el resto de la division, para sacar los minutos
                this.horasPersonales=Math.floor(me.minutosPersonales/60).toFixed(0);//Saca solo la hora, o decimales
                this.totalHorasMinutosPersonales=this.horasPersonales+","+this.restoMinutosPersonal;//Concatena hora y minutos

                var restoMinutosSalida,horasSalida,totalHorasMinutosSalida;

                this.restoMinutosSalida= me.minutosSalidas%60; //Me da el resto de la division, para sacar los minutos
                this.horasSalida=Math.floor(me.minutosSalidas/60).toFixed(0);//Saca solo la hora, 0 decimales
                this.totalHorasMinutosSalida=this.horasSalida+","+this.restoMinutosSalida;//Concatena hora y minutos


                var restoSuma, suma,horaTotal,totalHorasTrabajadas;

                //this.suma = me.minutosIncidencias+me.minutosSalidas-me.minutosPersonales;
                this.suma = me.minutosPersonales;
                this.restoSuma = this.suma%60;
                this.horaTotal=Math.floor(me.suma/60).toFixed(0);//Saca solo la hora, 0 decimales
                this.totalHorasTrabajadas=this.horaTotal+","+this.restoSuma;//Concatena hora y minutos*/

                this.reportemensual.map(function(x){
                    //rows.push({fecha:moment(x.fecha).format("DD/MM/YYYY"),minutosTrabajados:x.minutosTrabajados,minutosPorTrabajar:x.minutosPorTrabajar,tiempoPresente:x.tiempoPresente,tiempoIncidencia:x.tiempoIncidencia,totalHoras:x.totalHoras});                    
                    rows.push({fecha:moment(x.fecha).format("DD/MM/YYYY"),tiempoPresente:x.tiempoPresente,tiempoIncidencia:x.tiempoIncidencia,diferenciaMinutos:x.diferenciaMinutos});                    
                });
                  // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 150},
                    addPageContent: function(data) {
                        doc.text("Informe de General :   " +moment(me.capturaFechaIncicio ).format('DD/MM/YYYY')+" hasta "+moment(me.capturaFechaFin).format('DD/MM/YYYY'), 40, 30);
                        
                        doc.setFontSize(12);
                        doc.setTextColor(40);
                        doc.setFontStyle('normal');
                        doc.text("Nombre: :  "+ me.capturaNombre,40,60);
                        doc.text("Empresa: :  "+ me.capturaEmpresa,40,80);
                        doc.text("Departamento: :  "+ me.capturaDepartamento,40,100);
                        doc.text("Días Trabajados: "+me.diasTrabajados, 40, 120);
                        doc.text("Minutos Trabajados: "+me.tiempoPresente, 40, 140);
                        doc.text("Minutos Incidencias: "+me.tiempoIncidencia, 250, 140);
                        doc.text("Total Minutos: "+me.totMinutos, 450, 140);
                    }
                });
                doc.save('Informe_General.pdf');
                
            },

            
            close () {
                this.dialog = false;
            },

            footer(){ 
                doc.text(150,285, 'page ' + doc.page); //print number bottom right
                doc.page ++;
            },

           
            filtrarfechas(){                
                let me=this;
                this.fechaInicio1= me.fechaInicio,
                this.fechaFin1 = me.fechaFin

                axios.post('api/Informes/InformeGeneral',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.capturaCodPersonal,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.reportemensual=response.data;

                    if(me.reportemensual=="")
                    {
                        Vue . swal ("!!! No hay datos que mostrar !!!");          
                    }
                    else
                    {
                        me.reportemensual=response.data;
                        me.capturaFechaIncicio=me.fechaInicio;
                        me.capturaFechaFin=me.fechaFin;
                        me.capturaCodPersonal = me.codPersonal;
                        me.capturaNombre = me.reportemensual[0].nombre;
                        me.capturaEmpresa = me.reportemensual[0].nomEmpresa;
                        me.capturaDepartamento = me.reportemensual[0].nomDepartamento;
                        me.diasTrabajados = me.reportemensual[0].diasTrabajados;
                        me.tiempoIncidencia = me.reportemensual[0].minIncidencia;
                        me.tiempoPresente = me.reportemensual[0].minTrabajados
                        me.diferenciaMinutos = me.reportemensual[0].diferenciaMinutos;
                        me.totMinutos =me.reportemensual[0].totMinutos;
                        /*me.DiasTrabajados();
                        me.MinutosTrabajados();
                        me.MinutosIncidencias();
                        me.MinutosPersonales();
                        me.MinutosSalidas();
                        me.MinutosFavorContra();*/
                        me.close();
                        me.limpiar();                           
                    }
                                            
                }).catch(function(error){
                    console.log(error);
                });
            },

            /* exportExcel: function () {
                $('#input-excel').change(function(e){
                var reader = new FileReader();
                reader.readAsArrayBuffer(e.target.files[0]);
                reader.onload = function(e) {
                        var data = new Uint8Array(reader.result);
                        var wb = XLSX.read(data,{type:'array'});
                        var htmlstr = XLSX.write(wb,{sheet:"sheet no1", type:'binary',bookType:'html'});
                        $('#wrapper')[0].innerHTML += htmlstr;
                }
                });
            },*/

            exportExcel: function () {
                let data = XLSX.utils.json_to_sheet(this.reportemensual)
                const workbook = XLSX.utils.book_new()
                const filename = 'devschile-admins'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, '${filename}.xlsx')
            },

            selectPersonal(){
                let me=this;
                var personalArray=[];
                me.codPersonal = this.$store.state.usuario.id
                axios.post('api/Notificaciones/SelectPersonal',{
                 'Personal':me.codPersonal,
                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.usuarios.push({text: x.nombre,value:x.personal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            },
            
            limpiar(){
               this.fechaInicio="";
               this.fechaFin="";
               //this.codPersonal="";
            },

             onchange: function() {
                 let me = this;   
                  me.capturaCodPersonal = this.codpersonal; 
                  console.log("Codigo Personal: "+me.capturaCodPersonal);
             },            

            Excel(){
                let me=this;
                me.capturaCodPersonal = this.codpersonal; 

                axios.post('api/Informes/InformeGeneralExcel', {
                   'fecha1':me.fechaInicio1,
                   'fecha2':me.fechaFin1,
                   'Personal':me.capturaCodPersonal,
                   'idclienteoclock':this.$store.state.usuario.idclienteoclock              
                })
                .then(function(response){
                    //me.empresas=response.data;     
                    Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");                                           
                })
                .catch(function(error){
                    console.log(error);
                });
            },

        
        }        
    }
</script>