<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
                <v-toolbar-title>Incidencias</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn v-on:click="listar()"  color="primary">Listar (Hoy)</v-btn>               
                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                            <v-text-field v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y" type="date" box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>
                             </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="incidencias"
                :search="search"
                class="elevation-1"
                 :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td style="display: none">{{ props.item.personal }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.nomEmpresa }}</td>
                    <td>{{ props.item.nomDepartamento }}</td>
                    <td>{{ props.item.rol}}</td>
                    <td>{{ props.item.fecha }}</td>
                    <td>{{ props.item.hora }}</td>
                    <td>{{ props.item.observaciones }}</td>
                </template>
                
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';

    export default {
        data(){
            return {
                incidencias:[],                
                dialog: false,
                headers: [
                    // { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Empleado', value: 'nombre' },
                    { text: 'Empresa', value: 'nomEmpresa' },
                    { text: 'Departamento', value: 'nomDepartamento' },
                    { text: 'Rol', value: 'rol' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Hora', value: 'hora' },             
                    { text: 'Observaciones', value: 'observaciones' },               
                ],
                search: '',
                editedIndex: -1,
                id: '',
                fecha:'',
                nombre:'',
                apellido1:'',
                apellido2:'',
                fechaInicio: null,
                fechaFin: null,
                pagination: {rowsPerPage: 12},
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Buscar Incidencia' : 'Actualizar Incidencia'
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
        },
        methods:{
            listar(){
                let me=this;
                me.codPersonal = this.$store.state.usuario.id;

                axios.post('api/Usuarios/Incidencias',{
                  'Personal':me.codPersonal,
                  'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.incidencias=null;
                    me.incidencias=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

            Excel(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;

                axios.post('api/Usuarios/IncidenciasExcel', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){
                    //me.empresas=response.data;                      
                    Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");    
                })
                .catch(function(error){
                    console.log(error);
                });
            },

            crearPDF(){
                var columns = [
                    {title: "Empleado", dataKey: "nombre"},
                    {title: "Empresa", dataKey: "nomEmpresa"},
                    {title: "Departamento", dataKey: "nomDepartamento"},
                    {title: "Rol", dataKey: "rol"},
                    {title: "Fecha", dataKey: "fecha"},
                    {title: "Hora", dataKey: "hora"},
                    {title: "Causa", dataKey: "observaciones"},        
                ];
                var rows = [];

                this.incidencias.map(function(x){
                    rows.push({nombre:x.nombre,nomEmpresa:x.nomEmpresa,nomDepartamento:x.nomDepartamento,rol:x.rol,fecha:x.fecha,hora:x.hora,observaciones:x.observaciones});
                });
                  // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Incidencias", 40, 30);
                    }
                });
                doc.save('Incidencias.pdf');
            },


            close () {
                this.dialog = false;
            },

           
            filtrarfechas(){
                
                let me=this;
                me.codPersonal = this.$store.state.usuario.id;
                axios.post('api/Usuarios/IncidenciasFiltrado',{
                    'fecha':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'Personal':me.codPersonal,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.incidencias=null;
                    me.incidencias=response.data;
                    me.close();                   
                }).catch(function(error){
                    console.log(error);
                });

                me.codPersonal = this.$store.state.usuario.id;

                axios.post('api/Usuarios/Incidencias',{
                  'Personal':me.codPersonal,
                  'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.incidencias=null;
                    me.incidencias=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            }   
        }        
    }
</script>