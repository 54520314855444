<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
                <v-toolbar-title>Personal</v-toolbar-title>                 
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>                    
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>                    
                    
                    <v-spacer></v-spacer>

                    <v-btn color="primary" dark class="mb-2" v-if=booltienePirineos @click="sincronizarPirineos()">Sincronizar Pirineos</v-btn>
                    

                    <v-dialog v-model="dialog" max-width="800px" :disabled="booltienePirineos">                       
                        <v-btn slot="activator" color="primary" dark class="mb-2">Nuevo</v-btn>                        
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="nif" label="Nif" box></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="nombre" label="Nombre" box></v-text-field>
                                </v-flex>

                               <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="apellido1" label="Apellido1" box>
                                    </v-text-field>
                                </v-flex>
                                 <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="apellido2" label="Apellido2" box>
                                    </v-text-field>
                                </v-flex> 

                                <v-flex xs12 sm6 md6>
                                  <v-select v-model="rol"
                                     :items="roles" label="Roles" box>
                                  </v-select>
                                </v-flex>

                                 <v-flex xs12 sm6 md6>
                                    <v-select  v-model="codempresa"
                                    :items="empresas" label="Empresa" box>
                                    </v-select>
                                </v-flex>

                                 <v-flex xs12 sm6 md6>
                                    <v-select  v-model="codcentro"
                                    :items="centros" label="Centro" box>
                                    </v-select>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-select  v-model="coddepartamento"
                                    :items="departamento" label="Departamento" box>
                                    </v-select>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="telefono" label="Teléfono" box>
                                    </v-text-field>
                                </v-flex>
                                 <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="movil" label="Móvil" box>
                                    </v-text-field>
                                </v-flex>

                                 <v-flex xs12 sm6 md6>
                                    <v-text-field  v-model="direccion" label="Dirección" box>
                                    </v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6 v-show="true">
                                    <v-text-field :disabled="true"  v-model="claveonesignal" label="Clave One Signal" box>
                                    </v-text-field>
                                </v-flex> 

                                <v-flex xs12 sm6 md6>
                                    <v-text-field v-model="email" label="Email" box>
                                    </v-text-field>
                                </v-flex>
                               
                                <v-flex xs12 sm6 md6>
                                    <v-text-field  type="password" v-model="password" label="Password" box>
                                    </v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field type="number" v-model="minutosTrabajados" label="Minutos Trabajados" box>
                                    </v-text-field>
                                </v-flex>

                                 <v-flex xs12 sm6 md6  v-if=boolCliente>
                                    <v-select  v-model="idcliente"
                                    :items="comboCliente" label="Cliente Oclock" box>
                                    </v-select>
                                  </v-flex>      

                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                    </div>
                                </v-flex>
                            
                                </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="guardar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==2">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==1">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==2">Activar </span>
                                <span v-if="adAccion==1">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="usuarios"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                        small
                        v-if=!booltienePirineos
                        class="mr-2"
                        @click="editItem(props.item)"
                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.baja">
                            <v-icon
                            small
                            v-if=!booltienePirineos
                            @click="activarDesactivarMostrar(2,props.item)"

                            >
                            block
                            </v-icon>
                         </template>
                             <template v-else>
                            <v-icon
                            small
                            v-if=!booltienePirineos
                            @click="activarDesactivarMostrar(1,props.item)"

                            >
                            check
                            </v-icon>
                        </template>
                        
                    </td>
                    <td style="display: none">{{ props.item.id }}</td>
                    <td style="display: none">{{ props.item.idEmpresa }}</td>
                    <td style="display: none">{{ props.item.idCentro }}</td>
                    <td style="display: none">{{ props.item.idDepartamento }}</td>                   
                    <td style="display: none">{{ props.item.nif }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.apellido1 }}</td>
                    <td>{{ props.item.apellido2 }}</td>
                    <td style="display: none">{{ props.item.telefono }}</td>
                    <td style="display: none">{{ props.item.movil }}</td>
                    <td style="display: none">{{ props.item.email }}</td>
                    <td style="display: none">{{ props.item.direccion }}</td>
                    <td>{{ props.item.nomEmpresa }}</td>
                    <td>{{ props.item.nomCentro }}</td>
                    <td>{{ props.item.nomDepartamento }}</td>
                    <td>{{ props.item.rol }}</td>
                    <td style="display: none">{{ props.item.idClienteOclock }}</td>
                    
                    <td>
                        <div v-if="props.item.baja">
                            <span class="red--text">Inactivo</span>
                        </div>
                        <div v-else>
                            <span class="blue--text">Activo</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">

                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>






<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';
    import swal from 'sweetalert2';
    window.Swal = swal;

    //import {mixinAdmin} from '@/mixins.js'

    export default {
        data(){
            return {
                usuarios:[],  
                comboCliente:[],               
                dialog: false,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Apellido 1', value: 'apellido1' },
                    { text: 'Apellido 2', value: 'apellido2' },                              
                    { text: 'Empresa', value: 'nomEmpresa' },    
                    { text: 'Centro', value: 'nomCentro' },    
                    { text: 'Dept', value: 'nomDepartamento' },  
                    { text: 'Rol', value: 'rol' },  
                    { text: 'Estado', value: 'baja' },  
                ],
                search: '',
                editedIndex: -1,
                id: '',
                idrol:'',
                nomcentro:'',
                codempresa:'',
                codcentro:'',
                codigo:'',
                coddepartamento:'',
                rol:'',
                empresas:[],                
                departamento:[],
                centros:[],
                cliente:[],                
                nif:'',
                nombre:'',
                nombreempresa:'',
                nomdepartamento:'',
                apellido1:'',
                apellido2:'',
                telefono: '',
                movil:'',
                email: '',
                password:'',
                actPassword:false,
                passwordAnt:'',
                valida: 0,
                idclienteoclock:0,
                validaMensaje:[],
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: '', 
                bolExiste:false,
                EstoyEditando:false,
                direccion:'',
                claveonesignal:'',
                pagination: {rowsPerPage: 12},
                booltienePirineos:false,
                minutosTrabajados:0,
                descripcion:'',
                roleA: ['Administrador','Usuario'],
                roleE: ['Ecomputer','Administrador','Usuario'], 
                roles:[],
                capturaCodigoCliente:'',                
                idcliente:'',
                codigocliente:'',
                boolCliente:''               
            }
        },

        //mixins:[mixinAdmin],

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuevo Personal' : 'Actualizar Personal'
            },
        },


        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            this.selectEmpresa();
            this.selectCentro();
            this.selectDepartamento();
            this.selectCliente();
            this.devolverNombreCliente();
            this.pirineos();
        },
        methods:{

            listar(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Usuarios/Listar', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                     //Verifica si el usuario es Master.
                     axios.post('api/ClienteOclocks/OcultarCliente',{
                        'codigo':me.codigocliente  
                        }).then(function(response){
                            me.boolCliente=response.data;
                        }).catch(function(error){
                            console.log(error);
                        }); 
                    ///
                    me.usuarios=response.data;                    
                })
                .catch(function(error){
                    console.log(error);
                });
            },

             crearPDF(){
                var columns = [
                    {title: "Nif", dataKey: "nif"},
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Apellido 1", dataKey: "apellido1"}, 
                    {title: "Apellido 2", dataKey: "apellido2"}, 
                    {title: "Empresa", dataKey: "nomEmpresa"},
                    {title: "Departamento", dataKey: "nomDepartamento"}
                ];
                var rows = [];

                this.usuarios.map(function(x){
                    rows.push({nif:x.nif,nombre:x.nombre,apellido1:x.apellido1,apellido2:x.apellido2,nomEmpresa:x.nomEmpresa,nomDepartamento:x.nomDepartamento});                    
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Personal", 40, 30);
                    }
                });
                doc.save('Personal.pdf');
            },

            Excel(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;

                axios.post('api/Usuarios/Excel', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){
                    //me.empresas=response.data;   
                    Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");               
                                  
                })
                .catch(function(error){
                    console.log(error);
                });
            },
        
            selectEmpresa(){
                let me=this;
                var empresaArray=[];
                axios.post('api/Empresas/SelectEmpresa',{
                'idclienteoclock':this.$store.state.usuario.idclienteoclock

                }).then(function(response){
                    empresaArray=response.data;
                    empresaArray.map(function(x){
                    me.empresas.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            }, 

            selectCentro(){
                let me=this;
                var centroArray=[];
                axios.post('api/Centros/SelectCentro',{
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    centroArray=response.data;
                    centroArray.map(function(x){
                    me.centros.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },


            selectDepartamento(){
                let me=this;
                var departamentoArray=[];
                axios.post('api/Departamentos/SelectDepartamento',{
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    departamentoArray=response.data;
                    departamentoArray.map(function(x){
                    me.departamento.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },  
        
            selectCliente(){
                let me=this;
                var clienteArray=[];
                axios.get('api/Usuarios/ListarClienteOclock')
                .then(function(response){
                    clienteArray=response.data;
                    clienteArray.map(function(x){
                    me.comboCliente.push({text: x.descripcion,value:x.codigo});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },
            
            onchange: function() {
            this.capturaCodigoCliente = this.codigo;        
            },

            editItem (item) {
                this.id=item.id;
                this.codempresa=item.idEmpresa;
                this.coddepartamento=item.idDepartamento;
                this.codcentro = item.idCentro;
                this.rol=item.rol;
                this.nif=item.nif;
                this.nombre=item.nombre;                    
                this.apellido1 = item.apellido1;
                this.apellido2 = item.apellido2;               
                this.telefono=item.telefono;
                this.movil = item.movil;
                this.email=item.email;
                this.codigo=item.idClienteOclock;
                this.claveonesignal=item.claveOneSignal;
                this.minutosTrabajados=item.minutosTrabajados;
                this.direccion= item.direccion;
                this.password=item.password_hash;
                this.passwordAnt=item.password_hash;
                this.editedIndex=1;
                this.dialog = true
                this.editedIndex=1;
                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
               this.id="";
                this.rol="";
                this.nombre="";
                this.nif="";
                this.apellido1="";
                this.apellido2="";
                this.telefono="";
                this.movil="";
                this.email="";
                this.claveonesignal='';
                this.minutosTrabajados="";
                this.direccion='';
                this.codempresa="";
                this.coddepartamento="";
                this.codcentro="";
                this.password="";
                this.passwordAnt="";
                this.actPassword=false;
                this.editedIndex=-1;
                this.validaMensaje="";
                this.codigo="";
            },

            guardar () {
                 let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;

                if (this.validar()){
                    return;
                }
                    axios.post('api/Usuarios/ExistePersonal',{
                        'nif': me.nif,
                        'idclienteoclock':this.$store.state.usuario.idclienteoclock
                        })                         
                    .then((response) => {                   
                        if(this.editedIndex > -1){
                             this.EstoyEditando=true;                         
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data;        
                           Vue . swal (this.nombre+ " con el documento " +this.nif + " ya esta registrado");                
                        }

                        //Verifica que e
                        if (me.password!=me.passwordAnt){
                            me.actPassword=true;
                        }

                        if(this.EstoyEditando==true && this.bolExiste==false)
                        {                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar
                                let me=this;
                                axios.post('api/Usuarios/Actualizar',{
                                   'id':me.id,
                                   'idEmpresa':me.codempresa,
                                   'idDepartamento':me.coddepartamento,
                                    'idCentro':me.codcentro,
                                    'rol':me.rol,
                                    'nif':me.nif,
                                    'nombre':me.nombre,
                                    'apellido1':me.apellido1,
                                    'apellido2':me.apellido2,      
                                    'telefono': me.telefono,
                                    'movil':me.movil,
                                    'email':me.email,
                                    'direccion':me.direccion,
                                    'claveonesignal':me.claveonesignal,
                                    'minutosTrabajados':me.minutosTrabajados,
                                    'password':me.password,
                                    'act_password':me.actPassword,
                                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                        else
                        {
                                if(this.$store.state.usuario.rol=="Ecomputer")
                                 {      
                                    this.codigocliente = this.idcliente;
                                 }
                                 else
                                 {
                                    this.codigocliente = this.$store.state.usuario.idclienteoclock;
                                 }

                                 console.log("Empresa: "+me.codempresa);
                                 console.log("Departamento: "+me.coddepartamento);
                                 console.log("Centro: "+me.codcentro);
                                 console.log("Codigo Cliente: "+me.codigocliente);


                            if (this.EstoyEditando==false && this.bolExiste==false){
                                let me=this;                               
                                //Código para guardar
                                axios.post('api/Usuarios/Crear',{
                                   'idEmpresa':me.codempresa,
                                   'idDepartamento':me.coddepartamento,
                                    'idCentro':me.codcentro,
                                    'rol':me.rol,
                                    'nif':me.nif,
                                    'nombre':me.nombre,
                                    'apellido1':me.apellido1,
                                    'apellido2':me.apellido2,
                                    'telefono': me.telefono,
                                    'movil':me.movil,
                                    'email':me.email,
                                    'direccion':me.direccion,
                                    'claveonesignal':me.claveonesignal,
                                    'minutosTrabajados':me.minutosTrabajados,
                                    'password':me.password,
                                    'idclienteoclock': me.codigocliente
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }         
                        }                        
                    })
                        .catch((response) => {
                        console.log(response);
                    })
            },

            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.nombre.length<3 || this.nombre.length>50){
                    this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.id;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                axios.post('api/Usuarios/Activar',{
                  'id':me.adId,
                  'idclienteoclock':this.$store.state.usuario.idclienteoclock
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(){
                let me=this;
                axios.post('api/Usuarios/Desactivar',{
                  'id':me.adId,
                  'idclienteoclock':this.$store.state.usuario.idclienteoclock
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },  
            
           pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },


            devolverNombreCliente(){
                let me=this;
                axios.post('api/ClienteOclocks/DevuelveNombre',{
                'codigo':this.$store.state.usuario.idclienteoclock,
                }).then(function(response){
                me.nombre = response.data;  
                if(me.nombre=="Ecomputer")
                {
                   me.roles=me.roleE;
                }
                else
                {
                    me.roles=me.roleA;
                }                  
                }).catch(function(error){
                    console.log(error);
                });
            }, 

            sincronizarPirineos(accion,item)
            {
                 let me=this;
                  me.clienteSincronizar=this.$store.state.usuario.idclienteoclock,

                    Vue . swal({
                    title: '¿Desea Sincronizar Pirineos?',
                    text:"",
                    showCancelButton: true,
                    confirmButtonText: 'Si!',
                    cancelButtonText: 'No!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: function() {
                    
                    }
                    }).then((result) => {
                    if(result.value) {
                        axios.post('api/Servidores/SincronizaPirineos',{
                        'idclienteoclock': me.clienteSincronizar
                        }).then(function(response){
                        }).catch(function(error){
                            console.log(error);
                        });

                         Vue . swal({
                            title: 'Descargando...',
                            timer: 20000,
                            text: 'La ventana se cerrará en cuanto termine la descarga',
                            didOpen : function () {
                            Vue . swal.showLoading()
                            }
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    this.listar();
                                }
                            }
                        )   

                    } else {
                        Vue . swal('Cancelada', 'Sincronización cancelada', 'info')
                    }
                    })                    
            },

           
         

        }        
    }

</script>








