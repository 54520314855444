import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
Vue.config.productionTip = false
Vue.prototype.moment = moment
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from 'vue-sweetalert2';
import VModal from 'vue-js-modal'


Vue.use(VModal)
Vue.use(VueSweetalert2);

Vue.config.productionTip = false
//La única vez que debemos cambiar la ruta de donde estoy obteniendo los servicios Restfull, sería aquí
axios.defaults.baseURL='https://oclock.ecomputer.es/'

Vue.use(VueGoogleMaps, {
  load: { key: "AIzaSyAaOvpvojhytlZTi7UtfhpBjpu921nSrk0" }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})




