import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Rol from './components/Rol.vue'
import Usuario from './components/Usuario.vue'
import Login from './components/Login.vue'
import Empresa from './components/Empresa.vue'
import Departamento from './components/Departamento.vue'
import HorarioEmpresa from './components/HorarioEmpresa.vue'
import Notificacion from './components/Notificacion.vue'
import Misdatos from './components/Misdatos.vue'
import store from './store'
import vacaciones from './components/Vacaciones.vue'
import presentes from './components/Presentes.vue'
import incidencias from './components/Incidencias.vue'
import causas from './components/Causas.vue'
import Centro from './components/Centro.vue'
import Pais from './components/Pais.vue'
import Provincia from './components/Provincia.vue'
import Poblacion from './components/Poblacion.vue'
import FichajeManual from './components/FichajeManual.vue'
import InformeGeneral from './components/InformeGeneral.vue'
import InformeGeneralFichajes from './components/InformeGeneralFichajes.vue'
import InformeFichaje from './components/InformeFichaje.vue'
import ValidarFichajes from './components/ValidarFichajes.vue'
import InformeIncidencia from './components/InformeIncidencia.vue'
import Nominas from './components/Nominas.vue'
import ClienteOclock from './components/ClienteOclock.vue'
import InformePush from './components/InformePush.vue'
import ClavesAsignadas from './components/ClavesAsignadas.vue'
import SesionesMoviles from './components/SesionesMoviles.vue'
import MapeoMoviles from './components/MapeoMoviles.vue'
import Servidor from './components/Servidor.vue'
import TrazabilidadMovil from './components/TrazabilidadMovil.vue'

Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/causas',
      name: 'causas',
      component: causas,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },
    {
      path: '/incidencias',
      name: 'incidencias',
      component: incidencias,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },
    {
      path: '/presentes',
      name: 'presentes',
      component: presentes,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true,
        ecomputer:true
      }
    },
    {
      path: '/vacaciones',
      name: 'vacaciones',
      component: vacaciones,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },

    {
      path: '/',
      name: 'home',
      component: Home,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },
    {
      path: '/misdatos',
      name: 'misdatos',
      component: Misdatos,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },
    {
      path: '/roles',
      name: 'roles',
      component: Rol,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: Usuario,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/empresas',
      name: 'empresas',
      component: Empresa,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/departamentos',
      name: 'departamentos',
      component: Departamento,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/horarioEmpresas',
      name: 'horarioEmpresas',
      component: HorarioEmpresa,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/notificaciones',
      name: 'notificaciones',
      component: Notificacion,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/centros',
      name: 'centros',
      component: Centro,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/paises',
      name: 'paises',
      component: Pais,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/provincias',
      name: 'provincias',
      component: Provincia,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/poblaciones',
      name: 'poblaciones',
      component: Poblacion,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    {
      path: '/fichajemanual',
      name: 'fichajemanual',
      component: FichajeManual,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },

    {
      path: '/validarFichajes',
      name: 'validarFichajes',
      component: ValidarFichajes,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/clavesasignadas',
      name: 'clavesasignadas',
      component: ClavesAsignadas,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/sesionesmoviles',
      name: 'sesionesmoviles',
      component: SesionesMoviles,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },
    {
      path: '/mapeomoviles',
      name: 'mapeomoviles',
      component: MapeoMoviles,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    {
      path: '/trazabilidadmovil',
      name: 'trazabilidadmovil',
      component: TrazabilidadMovil,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    {
      path: '/InformeGeneral',
      name: 'InformeGeneral',
      component: InformeGeneral,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    {
    path: '/informeGeneralFichaje',
    name: 'informeGeneralFichaje',
    component: InformeGeneralFichajes,
    meta :{
      administrador :true,
      ecomputer:true
    }
  },

    {
      path: '/InformeFichaje',
      name: 'InformeFichaje',
      component: InformeFichaje,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    {
      path: '/InformeIncidencia',
      name: 'InformeIncidencia',
      component: InformeIncidencia,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    {
      path: '/nominas',
      name: 'nominas',
      component: Nominas,
      meta :{
        administrador :true,
        usuario: true,
        ecomputer:true
      }
    },

    {
      path: '/clienteOClock',
      name: 'clienteOClock',
      component: ClienteOclock,
      meta :{
            ecomputer :true,
      }
    },

    {
      path: '/servidor',
      name: 'servidor',
      component: Servidor,
      meta :{
            ecomputer :true,
      }
    },

    {
      path: '/informePush',
      name: 'informePush',
      component: InformePush,
      meta :{
        administrador :true,
        ecomputer:true
      }
    },

    
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta : {
        libre: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => 
{
  if (to.matched.some(record => record.meta.libre)){
    next()
  } else if (store.state.usuario && store.state.usuario.rol== 'Administrador'){
    if (to.matched.some(record => record.meta.administrador)){
      next()
    }
 
  }else if (store.state.usuario && store.state.usuario.rol== 'Usuario'){
    if (to.matched.some(record => record.meta.usuario)){
      next()
    }

  }else if (store.state.usuario && store.state.usuario.rol== 'Ecomputer'){
    if (to.matched.some(record => record.meta.ecomputer)){
      next()
    }

  } else{
    next({
      name: 'login'
    })  
  }
})


export default router

