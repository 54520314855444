<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>print</v-icon></v-btn>
                <v-toolbar-title>Vacaciones</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>                                           
                    <v-dialog v-model="dialog" max-width="500px" :disabled="booltienePirineos">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Nuevo</v-btn>                        
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
  
                                <v-container>
                                    <v-layout row wrap>
                                       
                                       <v-flex xs12 sm12 d-flex>
                                            <v-select v-on:change="onchange()"  v-model="idpersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal"
                                                box
                                            ></v-select>
                                        </v-flex>

                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="fechaInicio" dateformat="d M y" type="date"  label="Fecha Inicio"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm12 md12 lg12 xl12>
                                           <v-text-field v-model="observacion" label="Observación" box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6 v-show="valida">
                                            <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                    </div>
                                        </v-flex>

                                    </v-layout>
                                </v-container>                  
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" flat @click.native="close1">Cancelar</v-btn>
                                <v-btn color="success" flat @click.native="guardar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="adModal" max-width="290" :disabled="booltienePirineos">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==1">Aprobar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==2">Denegar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==1">Aprobar </span>
                                <span v-if="adAccion==2">Denegar </span>
                                las vacaciones de {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="aprobarDenegarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="aprobar">
                                    Aprobar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="denegar">
                                    Denegar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog1" max-width="500px" :disabled="booltienePirineos">
                        <v-btn  slot="activator" color="primary" dark class="mb-2">Dias</v-btn>                        
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "Asignar Días" }}</span>
                            </v-card-title>
  
                                <v-container>
                                    <v-layout row wrap>

                                        <v-flex xs12 sm12 d-flex >
                                            <v-select v-on:change="onchange2()" v-model="idpersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal"
                                                box                                                
                                            ></v-select>
                                        </v-flex>    

                                        <v-flex xs12 sm12>
                                           <v-text-field v-model="Nasignados" label="Días Asignados" box :disabled="true"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6 md6>
                                           <v-text-field v-model="Ddisfrutados" label="Días Disfrutados" box :disabled="true"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6 md6>
                                           <v-text-field v-model="Dpendientes" label="Días Pendientes" box :disabled="true"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6 md6>
                                            <v-text-field type="number" maxlength="4" :min="1" :max="2060" v-model="Nano" label="Año" box>
                                            </v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm6 md6>
                                            <v-text-field type="number" maxlength="4" :min="1" :max="30" v-model="Ndias" label="Días"  box>
                                            </v-text-field>
                                        </v-flex>
                                       
                                    </v-layout>
                                </v-container>                  
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" flat @click.native="close2" >Cancelar</v-btn>
                                <v-btn color="success" flat @click.native="Updatedias" v-if=soyAdmin>Actualizar</v-btn>
                                <v-btn color="success" flat @click.native="Creardias" v-if=soyAdmin>Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                     <v-dialog v-model="dialog2" max-width="700px" :disabled="booltienePirineos">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-on:change="onchange()"  v-model="idpersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal" box>
                                            </v-select>
                                        </v-flex>     
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close3">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>  
                        <v-btn color="primary"  @click.native="listar()" dark class="mb-2">Listar</v-btn>                        
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="vacaciones" 
                :search="search"
                class="elevation-1" 
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon 
                        small
                        v-if=!booltienePirineos
                        class="mr-2"
                        @click="editItem(props.item)"                    
                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.estado=='Aprobado'">
                            <v-icon
                            small
                            @click="aprobarDenegarMostrar(2,props.item)"
                            v-if=soyAdmin && !booltienePirineos
                            >
                            block
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            small
                            @click="aprobarDenegarMostrar(1,props.item)"
                            v-if=soyAdmin && !booltienePirineos
                            >
                            check
                            </v-icon>
                        </template>
                    </td>
                    <td style="display: none">{{ props.item.id }}</td>
                    <td style="display: none">{{ props.item.codPersonal }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.fechaInicio }}</td>
                    <td>{{ props.item.fechaFin }}</td>   
                    <td>{{ props.item.dias }}</td>  
                    <td>{{ props.item.diasPendientes }}</td>
                    <td>{{ props.item.diasDisfrutados }}</td>
                    <td style="display: none" >{{ props.item.observacion }}</td>                 
                    <td>
                        <div v-if="props.item.estado=='Solicitado'">
                            <span class="red--text">Solicitado</span>
                        </div>

                        <div v-if="props.item.estado=='Aprobado'">
                            <span class="blue--text">Aprobado</span>
                        </div>

                        <div v-if="props.item.estado=='Denegado'">
                            <span class="red--text">Denegado</span>
                        </div>
                        
                    </td>
                </template>                
            </v-data-table>
        </v-flex>
    </v-layout>
  </template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';

    var codPersonal = "";

    export default {
        data(){
            return {
                vacaciones:[],
                vacaciones2:[],  
                mostrarVaca:[],
                diasAsignados:[],   
                usuarios:[],           
                dialog: false,
                dialog1: false,
                dialog2: false,
                headers: [//Creamos la cabecera del datatable
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Nombre', value: 'nombre'},                
                    { text: 'Fecha Inicio', value: 'fechaInicio', sortable: false  },  
                    { text: 'Fecha Fin', value: 'fechaFin', sortable: false },     
                    { text: 'Días a Disfrutar', value: 'dias', sortable: false },     
                    { text: 'Días Pendientes', value: 'diasPendientes', sortable: false },     
                    { text: 'Días Disfrutados', value: 'diasDisfrutados', sortable: false }, 
                    { text: 'Estado', value: 'baja', sortable: false  }                          
                ],
                search: '',
                editedIndex: -1,
                valida: 0,
                validaMensaje:[],
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: ''  ,
                
                id:'',
                codPersonal:'',
                estado:'',
                fechaInicio:'',
                fechaFin:'',
                estado:'',
                observacion:'',
                tipoRol:false,
                nombreRol:'',
                Dias:'',
                Anyo:'',
                capturaCodPersonal:'',
                idpersonal:'',
                diasVacaciones:'',
                diasDisfrutados:'',
                diasPendientes:'',
                IdClienteOclock:0,

                //Para el modal días
                Npersonal:'',
                Nasignados:'',
                Ddisfrutados:'',
                Dpendientes:'',
                Nano:'',
                Ndias:'',
                pagination: {rowsPerPage: 12},

                soyAdmin:false,
                booltienePirineos:''
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Asignar Vacaciones' : 'Actualizar Vacaciones'
            },  
        },
        watch: {
            dialog (val) {
            val || this.close1()
            val || this.close3()
            }
        },

        created () {       
            this.listar();
            this.selectPersonal();
            this.EsAdministrador();
            this.pirineos();
        },

        methods:{
             listar(){                 
                let me=this; 

                this.IdClienteOclock = this.$store.state.usuario.idclienteoclock
                me.codPersonal = this.$store.state.usuario.id;

                axios.post('api/Vacaciones/Listar',{
                'idPersonal':me.codPersonal,
                'idclienteoclock':this.IdClienteOclock
                }).then(function(response){
                     me.vacaciones=response.data;
                     console.log("Vacaciones: "+me.vacaciones);
                }).catch(function(error){
                    console.log(error);
                });
            },

            EsAdministrador(){                 
                let me=this; 
                me.IdClienteOclock = this.$store.state.usuario.idclienteoclock
                me.codPersonal = this.$store.state.usuario.id;

                axios.post('api/Usuarios/EsAdminOrUser',{
                'id':me.codPersonal,
                'idclienteoclock':me.IdClienteOclock
                }).then(function(response){
                     me.soyAdmin=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

            close1 () {
                this.dialog = false;
                this.limpiar();
            },

             close2 () {
                this.dialog1 = false;
                this.limpiardias();
            },

            close3 () {
                this.dialog2= false;
                this.limpiar();
            },
        
                selectPersonal(){
                    let me=this;
                    var personalArray=[];
                    codPersonal = this.$store.state.usuario.id;
                    me.IdClienteOclock = this.$store.state.usuario.idclienteoclock
                    
                    axios.post('api/Vacaciones/SelectPersonal',{
                        'idpersonal':codPersonal,
                        'idclienteoclock':me.IdClienteOclock

                        }).then(function(response){
                        personalArray=response.data;
                        personalArray.map(function(x){
                        me.usuarios.push({text: x.nombre,value:x.idPersonal});                    
                        });
                    }).catch(function(error){
                        console.log(error);
                    });
                },

            editItem (item) {
                this.id=item.id;
                this.idpersonal = item.idPersonal;
                this.fechaInicio = this.convertirfecha(item.fechaInicio);
                this.fechaFin = this.convertirfecha(item.fechaFin);
                this.observacion = item.observacion;                            

                this.editedIndex=-1;

                this.editedIndex=1;

                this.dialog = true
            },

            close1 () {
                this.dialog = false;
                this.limpiardias();
            },

            limpiar(){
                this.id="";
                this.codPersonal="";
                this.fechaInicio="";
                this.fechaFin="";
                this.observacion="";
                this.Dias="";
                this.Anyo="";
                this.idpersonal=";"
     
                this.editedIndex=-1;
            },

            limpiardias(){
               this.idpersonal = "";
               this.Nasignados="";
               this.Ddisfrutados="";
               this.Dpendientes="";
               this.Nano="";
               this.Ndias="";
            },

             limpiardias2(){
               this.Nasignados="";
               this.Ddisfrutados="";
               this.Dpendientes="";
               this.Nano="";
               this.Ndias="";
            },

            convertirfecha (fecha){
               var res = fecha.split("/");
               var fechabuena = res[2]+"-"+res[1]+"-"+res[0]
               return fechabuena;
            },

            guardar () {
                if (this.validar()){
                    return;
                }

                if (this.editedIndex > -1) {
                    //Código para editar.
                    //Código para guardar.
                    let me=this;
                    me.IdClienteOclock = this.$store.state.usuario.idclienteoclock

                    axios.post('api/Vacaciones/Actualizar',{
                        'id':me.codPersonal,//Usuario logueado
                        'idPersonal':me.capturaCodPersonal, //Usuario que escogen del combo
                        'fechaInicio':me.fechaInicio,
                        'fechaFin':me.fechaFin,
                        'estado':me.Estado,
                        'observacion':me.observacion,
                        'idclienteoclock':me.IdClienteOclock

                    }).then(function(response){
                        me.close1();
                        me.listar();
                        me.limpiar();                        
                    }).catch(function(error){
                        console.log(error);
                    });
                } else {
                    //Código para guardar.
                    let me=this;

                    if (this.soyAdmin == true)
                    {          
                       me.IdClienteOclock = this.$store.state.usuario.idclienteoclock

                       axios.post('api/VacacionesAnuales/VerificaSiExiste',{
                      'IdPersonal':me.capturaCodPersonal,
                      'idclienteoclock':me.IdClienteOclock

                      }).then(function(response){
                         if(response.data==false)
                         {
                            Vue . swal ("!!! No tiene vacaciones asignadas para este año !!!"); 
                         }
                         else
                         {           
                             axios.post('api/Vacaciones/Crear',{
                            'id':me.codPersonal,//Usuario logueado
                            'idPersonal':me.capturaCodPersonal, //Usuario que escogen del combo
                            'FechaInicio':me.fechaInicio,
                            'FechaFin':me.fechaFin,
                            'Estado':me.baja,
                            'observacion':me.observacion,
                            'idclienteoclock':me.IdClienteOclock

                            }).then(function(response){
                                me.close1();
                                me.listar();
                                me.limpiar();                        
                            }).catch(function(error){
                                console.log(error);
                            });                            
                         }       
                      }).catch(function(error){
                         console.log(error);
                      });                                       
                    }                   
                    else
                    {   
                        let me=this;
                        me.IdClienteOclock = this.$store.state.usuario.idclienteoclock

                        axios.post('api/VacacionesAnuales/VerificaSiExiste',{
                        'Idpersonal':me.capturaCodPersonal,
                        'idclienteoclock':me.IdClienteOclock

                        }).then(function(response){
                            if(response.data==false)
                            {
                                 Vue . swal ("!!! No tiene vacaciones asignadas para este año !!!"); 
                            }
                            else
                            {                
                                axios.post('api/Vacaciones/Crear',{
                                'id':me.codPersonal,//Usuario logueado
                               'idPersonal':me.capturaCodPersonal, //Usuario que escogen del combo
                                'FechaInicio':me.fechaInicio,
                                'FechaFin':me.fechaFin,
                                'Estado':me.Estado,
                                'observacion':me.observacion,
                                'idclienteoclock':me.IdClienteOclock

                                }).then(function(response){
                                    me.close1();
                                    me.listar();
                                    me.limpiar();                        
                                }).catch(function(error){
                                    console.log(error);
                                });    
                            }
                            }).catch(function(error){
                                console.log(error);
                            });    
                        }  
                    }                                  
                },


            Creardias()
            {
                let me=this;  

                if(me.Ndias != "" && me.Nano != "")
                {
                    axios.post('api/VacacionesAnuales/VerificaSiExiste',{
                    'idPersonal':me.capturaCodPersonal,
                    'idclienteoclock':me.IdClienteOclock          

                    }).then(function(response){   

                        if(response.data==false)
                        {
                            axios.post('api/VacacionesAnuales/Crear',{
                            'idPersonal':me.capturaCodPersonal,
                            'idclienteoclock':me.IdClienteOclock,          
                            'Dias':me.Ndias,
                            'Anyo':me.Nano,
                            }).then(function(response){            
                                Vue . swal ("Días asignados correctamente"); 
                                me.close2();                                
                            }).catch(function(error){
                                console.log(error);
                            });
                        }
                        else
                        {
                            Vue . swal ("Ya existen días asignados para el año: "+me.Nano); 
                        }

                        }).catch(function(error){
                            console.log(error);
                        });                
                }
                else
                {
                    Vue . swal ("Faltan datos por completar"); 
                } 

                
            },

            Updatedias()
            {
                let me=this;    

                if(me.Ndias != "" && me.Nano != "")
                {
                    axios.post('api/VacacionesAnuales/Actualizar',{
                    'idPersonal':me.capturaCodPersonal,
                    'idclienteoclock':me.IdClienteOclock,          
                    'Dias':me.Ndias,
                    'Anyo':me.Nano
                    }).then(function(response){   
                        Vue . swal ("Los datos han sido actualizados."); 
                        me.close2();  
                    }).catch(function(error){
                       console.log(error);
                    });
                }
                else
                {
                    Vue . swal ("Faltan datos por completar"); 
                }                    
            },

            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.codPersonal == 0 && this.Dias.length==0 && this.Anyo.length==0){
                    this.validaMensaje.push("Selecciona un Empleado y una Fecha correcta");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            aprobarDenegarMostrar(accion,item){                                          
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.id;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            aprobarDenegarCerrar(){
                this.adModal=0;
            },
            aprobar(){
                let me=this;
                axios.post('api/Vacaciones/Aprobar',{
                  'id':me.adId,
                  'idclienteoclock':me.IdClienteOclock
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            denegar(){
                let me=this;
                axios.post('api/Vacaciones/Denegar',{
                  'id':me.adId,
                  'idclienteoclock':me.IdClienteOclock
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },

           /* RecibeRol(){                    
                let me=this;
                me.codPersonal = this.$store.state.usuario.codpersonal;

                axios.post('api/Usuarios/RolUsuario',{
                  'idPersonal':me.codPersonal,
                }).then(function(response){
                    me.nombreRol=response.data;

                    if (response.data=="Administrador")
                    {
                       me.tipoRol=true;
                       me.listar();
                    }
                }).catch(function(error){
                    console.log(error);
                });
            },*/

            crearPDF(){
                var columns = [
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Fecha Ini", dataKey: "fechaInicio"}, 
                    {title: "Fecha Fin", dataKey: "fechaFin"}, 
                    {title: "D. Asignados", dataKey: "dias"},
                    {title: "D. Pendientes", dataKey: "diasPendientes"},
                    {title: "D. Disfrutados", dataKey: "diasDisfrutados"},              
                ];
                var rows = [];

                this.vacaciones.map(function(x){
                    rows.push({nombre:x.nombre,fechaInicio:x.fechaInicio,fechaFin:x.fechaFin,dias:x.dias,diasPendientes:x.diasPendientes,diasDisfrutados:x.diasDisfrutados,Estado:x.estado?"Aprobado":"Denegado"});                    
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Vacaciones", 40, 30);
                    }
                });
                doc.save('Vacaciones.pdf');
            },

             onchange: function() {
                 let me = this;   

                  me.capturaCodPersonal = this.idpersonal; 

                  /*if(me.capturaCodPersonal !=0)
                  {
                    axios.post('api/VacacionesAnuales/UltimoAnyo',{
                        'id':me.capturaCodPersonal,
                        }).then(function(response){   
                            me.vacaciones=response.data;                            
                        }).catch(function(error){
                            console.log(error);
                        });

                  } */
             },

             onchange2: function() {
                 let me = this;   

                  if(me.capturaCodPersonal !=0)
                  {
                    axios.post('api/Vacaciones/VerDiasVacaciones',{
                        'idPersonal':me.capturaCodPersonal,
                        'idclienteoclock':this.$store.state.usuario.idclienteoclock
                        }).then(function(response){   
                            me.limpiardias2();       
                            me.vacaciones2=response.data; 

                            if(Object.entries(me.vacaciones2).length != 0)
                            {
                              me.Nasignados = me.vacaciones2[0].diasAsignados;  
                              me.Ddisfrutados = me.vacaciones2[0].diasDisfrutados;  
                              me.Dpendientes = me.vacaciones2[0].diasPendientes;  
                              me.Nano = me.vacaciones2[0].anyo;  
                              me.Ndias = me.vacaciones2[0].dias;        
                            }
                            else
                            {
                              me.Nasignados = 0;  
                              me.Ddisfrutados = 0;  
                              me.Dpendientes = 0;  
                              me.Nano = 0;  
                              me.Ndias = 0;  
                            }
                            
                            
                        }).catch(function(error){
                            console.log(error);
                        });
                  } 
             },

             filtrarfechas(){
                
                let me=this;    

                this.IdClienteOclock = this.$store.state.usuario.idclienteoclock
                me.codPersonal = this.$store.state.usuario.id;      

                axios.post('api/Vacaciones/Mostrar',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'idpersonal':me.codPersonal,
                    'idclienteoclock':this.IdClienteOclock
                }).then(function(response){
                      me.vacaciones=response.data;  
                     me.close3();
                     me.limpiar();       
                                            
                }).catch(function(error){
                    console.log(error);
                });
            },

            pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },


        
        }        
    }
</script>

<style>

.cabecera {
   background:red;
}
</style>