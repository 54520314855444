<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Mapeo de Móviles</v-toolbar-title>                 
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                     <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                      <v-btn color="primary"  @click.native="aperturas()" dark class="mb-2">Entrada(Hoy)</v-btn>  
                      <v-btn color="primary"  @click.native="cierres()" dark class="mb-2">Salida(Hoy)</v-btn>  
                      <v-btn color="primary"  @click.native="incidencia()" dark class="mb-2">Incidencia(Hoy)</v-btn>  
                      <v-btn color="primary"  @click.native="salidaincidencia()" dark class="mb-2">Cierre Incidencia(Hoy)</v-btn>  

                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-on:change="onchange()"  v-model="codpersonal"
                                                :items="usuarios"
                                                filled
                                                label="Personal" box>
                                            </v-select>
                                        </v-flex>  
                                        <v-flex xs12 sm12 md12 lg12 xl12>
                                            <v-select v-on:change="onchange1()"  v-model="tipo_sesion"
                                            :items="tiposSesion" 
                                             filled
                                            label="Tipo Sesión">
                                       </v-select>
                                </v-flex>   
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="moviles"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                   
                    <td>{{ props.item.nombre }}</td> 
                    <td>{{ props.item.fechaSession }}</td>
                    <td>{{ props.item.horaSession }}</td>
                    <td style="display: none">{{ props.item.IdSesion }}</td>
                    <td style="display: none">{{ props.item.IdPersonal }}</td>
                    <td style="display: none">{{ props.item.IdClienteOClock }}</td>              
                    <td>{{ props.item.community }}</td>
                    <td  style="display: none">{{ props.item.countryCode }}</td>
                    <td>{{ props.item.countryName }}</td>
                     <td>{{ props.item.province }}</td>
                    <td>{{ props.item.locality }}</td>
                    <td>{{ props.item.postalCode }}</td>
                    <td>{{ props.item.direction }}</td>                    
                    <td style="display: none">{{ props.item.idClienteOclock }}</td>
                     <td>
                        <div v-if="props.item.nameSesion == 'Entrada' || props.item.nameSesion == 'Incidencia'">
                            <span class="green--text">{{props.item.nameSesion}}</span>
                        </div>
                        <div v-else>
                            <span class="red--text">{{props.item.nameSesion}}</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">

                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';
    //import {tienePirineos} from '@/mixins.js'

    export default {
        data(){
            return {
                moviles:[],    
                usuarios:[],   
                dialog: false,          
                headers: [
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Fecha Sesion', value: 'fechaSession' },
                    { text: 'Hora Sesion', value: 'fechaSession' },
                    { text: 'Comunidad', value: 'community' },                               
                    { text: 'Nombre País', value: 'countryName' },   
                    { text: 'Provincia', value: 'province' },   
                    { text: 'Localidad', value: 'locality' },  
                    { text: 'Código Postal', value: 'postalCode' },  
                    { text: 'Dirección', value: 'direction' },  
                    { text: 'Sesión', value: 'nameSesion' },  
                ],
                search: '',
                editedIndex: -1,
                id: '',
                codigo:'',             
                nombre:'',
                codpersonal:'',
                fechaInicio: '',
                fechaFin: '',
                idclienteoclock:0,
                pagination: {rowsPerPage: 12},
                capturaCodigoCliente:'',                
                idcliente:'',
                codigocliente:'',
                boolCliente:'' ,
                tipo_sesion: '',
                tiposSesion: ['Entrada','Salida',"Incidencia", "Cierre Incidencia"], 
                nombresesion:'',
                boolSesion:''
            }
        },

        computed: {
           
        },

         watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.aperturas();
            this.selectPersonal();
        },

        methods:{
             close () {
                let me=this;
                this.dialog = false;
                me.limpiar();     
              },


            aperturas(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Sesiones/MapeoEntrada', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                    me.moviles=response.data;                 
                })
                .catch(function(error){
                    console.log(error);
                });
            },

             cierres(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Sesiones/MapeoSalida', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                    me.moviles=response.data;                
                })
                .catch(function(error){
                    console.log(error);
                });
            },

             incidencia(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Sesiones/MapeoIncidencia', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                    me.moviles=response.data;                
                })
                .catch(function(error){
                    console.log(error);
                });
            },

            salidaincidencia(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Sesiones/MapeoCierreIncidencia', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                    me.moviles=response.data;                    
                })
                .catch(function(error){
                    console.log(error);
                });
            },


            selectPersonal(){
                let me=this;
                var personalArray=[];
                me.codPersonal = this.$store.state.usuario.id
                axios.post('api/Notificaciones/SelectPersonal',{
                 'Personal':me.codPersonal,
                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.usuarios.push({text: x.nombre,value:x.personal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            },

            onchange: function() {
                 let me = this;   
                  me.capturaCodPersonal = this.codpersonal; 
             },   
             
             onchange1: function() {
                 let me = this;   
                  me.nombresesion = this.tipo_sesion;

                  if(me.nombresesion == "Entrada" || me.nombresesion == "Incidencia")
                  { 
                       me.boolSesion = true; 
                       
                  }

                  if(me.nombresesion == "Salida" || me.nombresesion == "Cierre Incidencia")
                  { 
                       me.boolSesion = false; 
                  }
             }, 
             
             limpiar()
            {
                this.fechaInicio="";
                this.fechaFin="";
                this.codpersonal="";
                this.tipo_sesion="";
            },
             
             filtrarfechas(){                
                let me=this;
                me.fechaInicio= this.fechaInicio,
                me.fechaFin = this.fechaFin

                axios.post('api/Sesiones/MapeoRangoFechas',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'nameSesion':me.nombresesion,
                    'typeSesion':me.boolSesion,
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock,
                    'idPersonal':me.capturaCodPersonal
                }).then(function(response){
                    me.moviles=response.data;
                    me.close();
                    me.limpiar();                           
                }).catch(function(error){
                    console.log(error);
                });
            },


             crearPDF(){
                var columns = [
                    {title: "Nif", dataKey: "nif"},
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Apellido 1", dataKey: "apellido1"}, 
                    {title: "Apellido 2", dataKey: "apellido2"}, 
                    {title: "Empresa", dataKey: "nomEmpresa"},
                    {title: "Departamento", dataKey: "nomDepartamento"}
                ];
                var rows = [];

                this.usuarios.map(function(x){
                    rows.push({nif:x.nif,nombre:x.nombre,apellido1:x.apellido1,apellido2:x.apellido2,nomEmpresa:x.nomEmpresa,nomDepartamento:x.nomDepartamento});                    
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Personal", 40, 30);
                    }
                });
                doc.save('Personal.pdf');
            },


        }        
    }
</script>
