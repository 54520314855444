<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Claves Push Asignadas</v-toolbar-title>                 
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    
                    <v-spacer></v-spacer>

                     <v-btn color="primary"  @click.native="activos()" dark class="mb-2">Activos</v-btn>  
                     <v-btn color="primary"  @click.native="desactivos()" dark class="mb-2">Desactivos</v-btn>  
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="moviles"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                   
                    <td style="display: none">{{ props.item.id }}</td>
                    <td style="display: none">{{ props.item.idEmpresa }}</td>
                    <td style="display: none">{{ props.item.idCentro }}</td>
                    <td style="display: none">{{ props.item.idDepartamento }}</td>                   
                    <td style="display: none">{{ props.item.nif }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.apellido1 }}</td>
                    <td>{{ props.item.apellido2 }}</td>
                    <td style="display: none">{{ props.item.telefono }}</td>
                    <td style="display: none">{{ props.item.movil }}</td>
                    <td style="display: none">{{ props.item.email }}</td>
                    <td style="display: none">{{ props.item.direccion }}</td>
                    <td>{{ props.item.nomEmpresa }}</td>
                    <td>{{ props.item.nomCentro }}</td>
                    <td>{{ props.item.nomDepartamento }}</td>
                    <td>{{ props.item.rol }}</td>
                    <td style="display: none">{{ props.item.idClienteOclock }}</td>
                    
                    <td>
                        <div v-if="props.item.claveOneSignal == '' ">
                            <span class="red--text">Sin Asignar</span>
                        </div>
                        <div v-else>
                            <span class="blue--text">Asignada</span>
                        </div>
                    </td>
                     <td>
                        <div v-if="props.item.geolocalizar == '' ">
                            <span class="red--text">Desactivado</span>
                        </div>
                        <div v-else>
                            <span class="blue--text">Activado</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">

                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';
    //import {tienePirineos} from '@/mixins.js'

    export default {
        data(){
            return {
                moviles:[],                 
                headers: [
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Apellido 1', value: 'apellido1' },
                    { text: 'Apellido 2', value: 'apellido2' },                              
                    { text: 'Empresa', value: 'nomEmpresa' },    
                    { text: 'Centro', value: 'nomCentro' },    
                    { text: 'Dept', value: 'nomDepartamento' },  
                    { text: 'Rol', value: 'rol' },  
                    { text: 'Clave Push', value: 'claveOneSignal' },  
                    { text: 'Geolocalizador', value: 'geolocalizar' },  
                ],
                search: '',
                editedIndex: -1,
                id: '',
                nomcentro:'',
                codempresa:'',
                codcentro:'',
                codigo:'',
                coddepartamento:'',
                rol:'',               
                nombre:'',
                nombreempresa:'',
                nomdepartamento:'',
                apellido1:'',
                apellido2:'',
                idclienteoclock:0,
                claveonesignal:'',
                pagination: {rowsPerPage: 12},
                capturaCodigoCliente:'',                
                idcliente:'',
                codigocliente:'',
                boolCliente:'' 
            }
        },

        computed: {
           
        },

        watch: {
           
        },

        created () {
            this.activos();
        },

        methods:{
            activos(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Usuarios/GeoActivos', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                    me.moviles=response.data;                    
                })
                .catch(function(error){
                    console.log(error);
                });
            },

             desactivos(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;
                axios.post('api/Usuarios/GeoDesactivos', {
                    'idclienteoclock':me.codigocliente                 
                })
                .then(function(response){
                    me.moviles=response.data;                
                })
                .catch(function(error){
                    console.log(error);
                });
            },

             crearPDF(){
                var columns = [
                    {title: "Nif", dataKey: "nif"},
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Apellido 1", dataKey: "apellido1"}, 
                    {title: "Apellido 2", dataKey: "apellido2"}, 
                    {title: "Empresa", dataKey: "nomEmpresa"},
                    {title: "Departamento", dataKey: "nomDepartamento"}
                ];
                var rows = [];

                this.usuarios.map(function(x){
                    rows.push({nif:x.nif,nombre:x.nombre,apellido1:x.apellido1,apellido2:x.apellido2,nomEmpresa:x.nomEmpresa,nomDepartamento:x.nomDepartamento});                    
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Personal", 40, 30);
                    }
                });
                doc.save('Personal.pdf');
            },


        }        
    }
</script>
