<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn :disabled="soyAdmin" @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
                <v-toolbar-title>Fichaje Manual</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>

                  <v-btn slot="activator" color="primary" dark class="mb-2">Entrada</v-btn>
                    <v-dialog v-model="dialog1" max-width="500px" >
                        <v-btn slot="activator" color="primary" dark class="mb-2" v-if=!booltienePirineos>Entrada</v-btn>                        
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "Crear Fichaje" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>

                                 <v-flex xs12 sm12 md12 lg12 xl12>
                                    <v-select v-on:change="onchange()" v-model="codpersonal"
                                        :items="usuarios"
                                         filled
                                         label="Personal" box>
                                    </v-select>
                                </v-flex>
                                                       

                                <v-flex xs12 sm12 md12 lg12 xl12>
                                    <v-select v-model="tipoFichaje"
                                        :items="tipoFichajes"
                                         filled
                                         label="Tipo Fichaje" box>
                                    </v-select>
                                </v-flex>   

                                <v-flex xs12 sm12 md12 v-show="tipoFichaje == 'Incidencia'">
                                    <v-select v-model="codCausa"
                                    :items="motivo" label="Causas" box>
                                    </v-select>
                                </v-flex>   

                                 <v-flex xs12 sm12 md12 v-show="tipoFichaje == 'Incidencia'">
                                  <v-text-field v-model="Observacionesinci" label="Observaciones" box></v-text-field>
                              </v-flex>      

                               <v-flex xs12 sm12>
                                    <v-text-field v-model="fechaInicio" label="Fecha Entrada" dateformat="d M y" type="date" box></v-text-field>
                               </v-flex>

                               <v-flex xs12 sm12>
                                  <v-text-field type="time"  v-model="fechaFin" label="Hora Entrada" box></v-text-field>
                                </v-flex>
                             </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close1">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="Entrada()">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>          
              </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="presentes"
                :search="search"
                class="elevation-1"
                :rows-per-page-items="[5,10,15, 20, 30, 40]"
                :pagination.sync="pagination">

                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0"> 
                    <v-dialog v-model="dialog3" max-width="500px">
                    <v-btn slot="activator" color="primary" dark class="mb-2"  @click="editItem1(props.item)">Salida</v-btn>                                            
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "Salida: "+nombre}}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>

                               <v-flex xs12 sm12>
                                    <v-text-field v-model="fechaInicio" label="Fecha Salida" dateformat="d M y" type="date" box></v-text-field>
                               </v-flex>

                               <v-flex xs12 sm12>
                                  <v-text-field type="time" v-model="fechaFin" label="Hora Salida" box></v-text-field>
                                </v-flex>
                                 <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="Observacionesinci" label="Observaciones" box></v-text-field>
                                </v-flex>  
                             </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close3">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="Salida(props.item)" v-if=!booltienePirineos>Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    </td>
                    <td style="display: none">{{ props.item.id }}</td>
                    <td style="display: none">{{ props.item.personal }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td style="display: none">{{ props.item.nomEmpresa }}</td>
                    <td>{{ props.item.entrada }}</td>
                    <td style="display: none">{{ props.item.rol}}</td>
                    <td>{{ props.item.fecha }}</td>
                    <td>{{ props.item.hora }}</td>                                     
                    <td>
                        <!-- <div v-if="props.item.condicion">
                            <span class="blue--text">Aprobado</span>
                        </div>
                        <div v-else>
                            <span class="red--text">Denegado</span>
                        </div> -->
                    </td>
                </template>  
                      
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script src="https://maps.google.com/maps/api/js?sensor=false"></script>

<script>

    import axios from 'axios'
    import jsPDF from 'jspdf'
    import moment from "moment";
    import autoTable from 'jspdf-autotable';
    import Vue from 'vue';
    
    var center= {latt: 0, long: 0};
    var Id=null;
    var codPersonal = null;
    var Personal = null;
    var Fecha= null;
    var HoraEntrada= null;
    var TiempoPresente= null;
    var TiempoIncidencia=null;
    var Longitud= null;
    var Latitud= null;
    var codCausa=null;
    var Observaciones = null;
    var Baja = null;
    var existeEntrada = null;
    var existeIncidencia = null;
    var existeHoraFechaEntrada = null;
    var existeHoraFechaIncidencia = null;
    var fechaSalida=null;
    var horaSalida=null;
    var IdClienteOclock=null;

    function getLocation() {
        if (navigator.geolocation) 
        {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            x.innerHTML = "Geolocation is not supported by this browser.";
        }
    }

   function showPosition(pos) {
        center['latt'] = pos.coords.latitude;
        center['long'] = pos.coords.longitude;

        Latitud = pos.coords.latitude;
        Longitud = pos.coords.longitude;
    }


    export default {
        data(){
            return {
                usuarios:[], 
                presentes:[],   
                incidencias:[],   
                tipoFichaje:[],   
                motivo:[],              
                dialog: false,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Empleado', value: 'nombre' },
                    { text: 'Tipo Entrada', value: 'Tipo' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Hora', value: 'hora' },               
                ],
                search: '',
                editedIndex: -1,
                id: '',
                nombre:'',
                apellido1:'',
                apellido2:'',

                fechaInicio:'',
                fechaFin:'',
               
                 dialog1: false,
                 dialog2: false,
                 dialog3: false,
                 dialog4: false,
                 dialog5: false,
                 dialog6: false,
                 dialog7: false,

                 Causainci: '',
                 Observacionesinci: '',
                 codCausa,
                 tipoIncidencia:'',
                 tiposIncidencias: ['Personales','Laborales'], 
                 tipoFichajes: ['Entrada','Incidencia'], 
                 soyAdmin:false,
                 permisoAdmin:false,
                 recibeCodPersona:0,
                 recibeTipoEntrada:'',
                 pagination: {rowsPerPage: 12},
                 codpersonal:'',
                 hefichado:'',
                 booltienePirineos:false,
            }
        },
         computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Filtro Manual' : ''
            },
        },

        watch: {
            dialog (val) {
            val || this.close1()
            || this.close2()
            || this.close3()
            || this.close4()
            || this.close4()
            || this.close5()
            || this.close6()
            || this.close7()
            },
    },

        created () {
            this.listar();
            this.selectPersonal();
            this.selectCausa();
            this.esAdmin();
            getLocation();
            this.pirineos();
        },

        methods:{
             listar(){
                let me=this;
                me.codPersonal = this.$store.state.usuario.id;
                me.IdClienteOclock = this.$store.state.usuario.idclienteoclock

                axios.post('api/Usuarios/PresentesIncidencias',{
                  'Personal':me.codPersonal,
                  'idclienteoclock': me.IdClienteOclock
                }).then(function(response){
                    me.presentes=null;
                    me.presentes=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

            Excel(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;

                axios.post('api/Usuarios/FichajeManualExcel', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){
                    //me.empresas=response.data;    
                     Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");            
                                  
                })
                .catch(function(error){
                    console.log(error);
                });
            },

            selectPersonal(){
                let me=this;
                var personalArray=[];
                axios.post('api/Notificaciones/SelectPersonal',{
                 'Personal':me.codPersonal,
                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.usuarios.push({text: x.nombre,value:x.personal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            }, 
            
            selectCausa (){
                let me=this;
                var causaArray=[];
                axios.post('api/Causas/SelectCausa',{
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    causaArray=response.data;
                    causaArray.map(function(x){
                    me.motivo.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            },

            onchange: function() {
            let me = this;   

            //Verifica si existe una Entrada
            me.recibeCodPersona=this.codpersonal
            axios.post("api/Horario/ExisteEntrada",{
            'Personal':me.recibeCodPersona,
            'idclienteoclock':this.$store.state.usuario.idclienteoclock
            })
            .then(function(response) {
            me.existeEntrada=response.data; 
            })
            .catch(function(error) {
            console.log(error); 
            });    

            ///Verifica si existe una Incidencia
            axios.post("api/Horario/ExisteIncidencia", {
            'Personal':me.codPersonal,
            'idclienteoclock':this.$store.state.usuario.idclienteoclock
            })
            .then(function(response) {
            me.existeIncidencia=response.data;      
            })
            .catch(function(error) {
            console.log(error);
            }); 
    },

    esAdmin(){
        let me=this;
          codPersonal = this.$store.state.usuario.id
          axios.post('api/Horario/EsAdmin',{
                'Personal':me.codPersonal,
                'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    me.soyAdmin=response.data;      
                }).catch(function(error){
                    console.log(error);
                });
        },

        editItem1 (item) {
                this.dialog3 = true;
                this.id=item.idFichaje;
                this.Personal = item.codPersonal;
                this.recibeTipoEntrada = item.entrada;
                this.nomEmpresa=item.nomEmpresa;
                this.nombre = item.nombre;
                this.nomDepartamento=item.nomDepartamento;
                this.rol = item.rol;
                this.fecha=item.fecha;
                this.hora=item.hora;           
            },

        editItem2 (item) {
                this.dialog4 = true;   
                this.idFichaje=item.idFichaje;
                this.Personal = item.codPersonal
                this.nomEmpresa=item.nomEmpresa;
                this.nombre = item.nombre;
                this.nomDepartamento=item.nomDepartamento;
                this.rol = item.rol;
                this.fecha=item.fecha;
                this.hora=item.hora;
            },    

      Entrada() {  
           let me = this; 

            var date = this.fechaInicio;
            var time = this.fechaFin;
            Fecha = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
            HoraEntrada = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
            var esadmin = me.soyAdmin;  

             //
            axios.post("api/Horario/HasFichado", 
            {
            'id':me.capturaId,
            'Personal':me.codpersonal,
            'horaEntrada':HoraEntrada,         
            'idclienteoclock':me.IdClienteOclock    
            })
            .then(function(response) {
               me.hefichado = response.data;
               console.log("He fichado: "+me.hefichado);

                if(me.hefichado)//Esta condicion es unicamenete si ha fichado y quiere volver a fichar dentro del Rango de Salida
                {
                   Vue . swal (" !! La hora entrada esta dentro del rango de salida !! ");    
                }
                else//Si la condición no se cumple, verifica la siguiente condición 
                {
                    IdClienteOclock = me.IdClienteOclock    
            
                    if(esadmin == false)
                    {
                    Baja=true;    
                    }
                    else
                    {
                    Baja=false; 
                    }

                    if(me.codPersonal != 0 || !me.fechaInicio || !me.fechaFin)
                    {            
                    Personal = me.codpersonal;
                    Fecha,
                    HoraEntrada,
                    Latitud;
                    Longitud;
                    Observaciones="";
                    Baja,

                    //Primero preguntamos si existe una Incidencia
                    axios.post("api/Horario/ExisteIncidencia", {
                    'Personal':Personal,
                    'idclienteoclock':IdClienteOclock
                    })
                    .then(function(response) {
                    me.existeIncidencia=response.data;  
                    
                    //Segundo preguntamos si existe una Entrada
                    axios.post("api/Horario/ExisteEntrada", {
                    'Personal':Personal,
                    'idclienteoclock':IdClienteOclock
                    })
                    .then(function(response) {
                        me.existeEntrada=response.data;    
                        
                        if (me.existeIncidencia) 
                        {
                        Vue . swal ("!! Cierre la Incidencia antes de Fichar(Entrada) !!");     
                        }
                        else
                        {
                        if (me.existeEntrada && me.tipoFichaje=="Entrada") 
                        {
                            //alert(me.codpersonal+ " !!! Ya ha fichado !!! ");
                            Vue . swal (" !! Ya has fichado, haga la salida !! ");    
                        }
                        else
                        {
                            if(me.tipoFichaje=="Entrada")
                            {
                            
                            if (window.confirm("!! Está seguro que desea Fichar  !!")) 
                            {
                                axios.post("api/Horario/CrearFichajeManual", 
                                {
                                    Personal,
                                    Fecha,
                                    HoraEntrada,
                                    Latitud,
                                    Longitud,
                                    Observaciones,
                                    Baja,
                                    IdClienteOclock

                                }).then(function(response) 
                                    {
                                        me.dialog1=false;
                                        me.codpersonal=0;
                                        me.fechaInicio="";
                                        me.fechaFin="";
                                        me.listar();
                                        
                                        Vue . swal ("!! Has Fichado en la Empresa !!");    
                                        me.existeEntrada = true;
                                    })
                                    .catch(function(error) 
                                    {
                                        console.log(error);
                                    }); 
                                }                     
                            }
                            else
                            {
                                var date = me.fechaInicio;
                                var time = me.fechaFin;
                                Fecha = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
                                HoraEntrada = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');

                                    Personal = me.codpersonal;
                                    Observaciones = me.Observacionesinci;
                                    TiempoPresente=0;
                                    TiempoIncidencia=0;
                                    codCausa = me.codCausa;

                                    if(me.codCausa==0 || me.Observacionesinci.trim().length == 0)
                                    {
                                    Vue . swal ("!! Faltan datos por completar !!");  
                                    }
                                    else
                                    {
                                        if (window.confirm("!! Está seguro de crear la incidencia  !!")) 
                                        {
                                            axios.post("api/Horario/CrearIncidenciaManual", {
                                            'Personal':me.recibeCodPersona,
                                            'fecha':Fecha,
                                            'horaEntrada': HoraEntrada,  
                                            'incidencia':codCausa,   
                                            'latitud':Latitud,
                                            'longitud': Longitud,  
                                            'observaciones':me.Observacionesinci,
                                            'idclienteoclock':IdClienteOclock
                                        })
                                    .then(function(response) {
                                        Vue . swal ("!! Incidencia Enviada correctamente !!");  
                                        me.listar();
                                        me.close1();
                                        me.recibeEntrada = false;
                                        me.existeIncidencia=true;
                                    })
                                    .catch(function(error) {
                                        console.log(error);
                                    });
                                }
                            } 
                        }                            
                    }                                       
                }
            })
            .catch(function(error) {
                console.log(error);
            });     
            })
                .catch(function(error) {
                console.log(error);
                });           
            }   
                        }

                    })
                    .catch(function(error) {
                    console.log(error);
                    });  

            

        //

            
},

Salida(item)
    {
        let me = this;      

       me.Personal =  item.personal; 
       me.IdClienteOclock = this.$store.state.usuario.idclienteoclock    
       
       var date = this.fechaInicio;
       var time = this.fechaFin;
       me.fechaSalida = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
       me.horaSalida = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
       me.Fecha = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');                       

       axios.post("api/Horario/HoraFechaEntrada", 
       {
          'Personal':me.Personal,
          'fecha':me.Fecha,
          'idclienteoclock':me.IdClienteOclock
       })
        .then(function(response) {
          me.existeHoraFechaEntrada=response.data;                                          
                              
          if(!me.existeHoraFechaEntrada)
          {
              Vue . swal ("!! La hora o la fecha es inferior a la entrada actual  !!");  
          }
          else
          {
              axios.post("api/Horario/ExisteEntrada", {//Verifica que exista una entrada
             'Personal':me.Personal,
             'idclienteoclock':me.IdClienteOclock 
             })
             .then(function(response) {
             me.existeEntrada=response.data;      
                
                axios.post("api/Horario/ExisteIncidencia", {//Verifica que exista una Salida
                'Personal':me.Personal,
                'idclienteoclock':me.IdClienteOclock   
                })
                .then(function(response) {
                    me.existeIncidencia=response.data;    
                    
                    if(!me.existeEntrada && !me.existeIncidencia)
                    {
                        Vue . swal ("!! No existen entradas que cerrar !!");  
                    }
                    else
                    {
                        if(me.existeEntrada && me.existeIncidencia && me.recibeTipoEntrada=="Entrada")
                        {
                           Vue . swal ("!! Cierre la incidencia antes de cerrar la Entrada !!");  
                        } 
                        else  if(me.existeIncidencia)
                        {
                            if (window.confirm("!! Está seguro que desea salir de la Incidencia  !!")) 
                            {
                                 console.log("Personal: "+me.codigopersonal);
                            //Me da el último Id de la Incidencia
                            axios.post("api/Horario/IdIncidencia", 
                            {
                                'Personal':me.Personal,
                                'idclienteoclock':me.IdClienteOclock   
                                })
                                .then(function(response) {
                                me.capturaId=response.data;  

                                
                                axios.post("api/Horario/SalirIncidenciaManual", {
                                    'id':me.capturaId,
                                    'Personal':me.Personal,
                                    'fechaSalida':me.fechaSalida,
                                    'horaSalida': me.horaSalida,     
                                    'latitud':Latitud,
                                    'longitud': Longitud,  
                                    'idclienteoclock':me.IdClienteOclock   
                                })
                                .then(function(response) {
                                    me.listar();
                                    me.close3();
                                    Vue . swal ("!! Ha salido de la incidencia !!"); 
                                    me.existeIncidencia=false; 
                                })
                                .catch(function(error) {
                                    console.log(error);
                                });  

                                
                                })
                                .catch(function(error) {
                                console.log(error);
                                });                           
                            }         
                        }
                        else  if(me.existeEntrada)
                        {
                            if (window.confirm("!!! Está seguro que desea salir de la empresa  !!!")) 
                                {
                                axios.post("api/Horario/IdEntrada", {
                                    'Personal':me.Personal,
                                    'idclienteoclock':me.IdClienteOclock    
                                })
                                .then(function(response) {
                                    me.capturaId=response.data;  
                                                                       
                                    axios.post("api/Horario/SalirEntradaManual", 
                                    {
                                    'id':me.capturaId,
                                    'Personal':me.Personal,
                                    'fechaSalida':me.fechaSalida,
                                    'horaSalida': me.horaSalida,        
                                    'latitud':Latitud,
                                    'longitud': Longitud,  
                                    'idclienteoclock':me.IdClienteOclock    
                                    })
                                    .then(function(response) {
                                        me.listar();
                                        me.close3();
                                        Vue . swal ("!! Ha salido correctamente !!"); 
                                            me.existeSalida=response.data;  
                                            me.existeEntrada=false; 
                                        })
                                        .catch(function(error) {
                                        console.log(error);
                                    });
                                        
                                    })
                                    .catch(function(error) {
                                        console.log(error);
                                    });             
                                }
                            }
                    
                        }              
                        })
                        .catch(function(error) {
                            console.log(error);
                        });         

                            
                        })
                        .catch(function(error) {
                        console.log(error);
                        }); 
            }           
          }).catch(function(error){
            console.log(error);
          });                       
    },
        
            crearPDF(){
                var columns = [
                    {title: "Empleado", dataKey: "nombre"},
                    {title: "Empresa", dataKey: "nomEmpresa"},
                    {title: "Departamento", dataKey: "nomDepartamento"},
                    {title: "Rol", dataKey: "rol"},
                    {title: "Fecha", dataKey: "fecha"},
                    {title: "Hora", dataKey: "hora"},    
                ];
                var rows = [];

                this.presentes.map(function(x){
                    rows.push({nombre:x.nombre,nomEmpresa:x.nomEmpresa,nomDepartamento:x.nomDepartamento,rol:x.rol,fecha:x.fecha,hora:x.hora});
                });
                  // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Presentes", 40, 30);
                    }
                });
                doc.save('Presentes.pdf');
            },

            close1 () {
                //Cierra el modal entrada
                this.dialog1 = false;
                this.codigopersonal=0;
                this.fechaInicio="";
                this.fechaFin="";    
                this.tipoFichaje="";
                this.codCausa="";
                this.Observacionesinci="";
                this.tipoFichaje="";
            },

            close2 () {
                this.dialog2 = false;   
                this.fechaInicio="";
                this.fechaFin="";             
            },
            close3 () {
                this.dialog3 = false;
                this.fechaInicio="";
                this.fechaFin="";    
            },
            close4 () {
                this.dialog4 = false;
                this.fechaInicio="";
                this.fechaFin="";
                this.codCausa=0;
                this.Observacionesinci="";
            },
             close5 () {
                this.dialog5 = false;
                this.fechaInicio="";
                this.fechaFin="";
                this.codCausa=0;
                this.Observacionesinci="";
            },
             close6 () {
                this.dialog6 = false;
                this.fechaInicio="";
                this.fechaFin="";    
            },

            close7 () {
                this.dialog7 = false;  
            },

            pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },
            

   /* ExisteSalidaAdmin(){
       let me = this;
       //me.codPersonal = this.$store.state.usuario.codpersonal   
       //me.codPersonal = me.codpersonal;  
        me.codPersonal = this.codpersonal;     
       axios
        .post("api/Horario/ExisteSalidaAdmin", {
          codPersonal,
          Fecha,
        })
        .then(function(response) {
          me.existeSalida=response.data;        
        })
        .catch(function(error) {
          console.log(error);
        });        
    },*/

    
    
    //Verifica Entrada o Salida
   /* EntradaOIncidencia()
    {
         let me = this;    
           
           if(me.tipoFichaje=="Entrada")
           {
                if (me.existeIncidencia) 
                {
                    alert("!!! Cierre la Incidencia antes de Fichar(Entrada) !!!");
                }
                else
                {
                    if (me.existeEntrada)
                    {
                        alert("!!! Ya existe una entrada !!!");
                    } 
                    else
                    {
                        this.entradaAdmin();
                    }
                }
           }

           if(me.tipoFichaje=="Incidencia")
           {
                if (!me.existeIncidencia) 
                {
                   this.incidenciaAdmin();
                }
                else
                {
                    alert("!!! Cierre la Incidencia antes de crear otra) !!!");
                }
           }    
    },

        entradaAdmin() {  
         let me = this; 

            var date = this.fechaInicio;
            var time = this.fechaFin;
            Fecha = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
            var esadmin = me.soyAdmin;  
            
            if(esadmin == false)
            {
               Condicion=true;    
            }
            else
            {
               Condicion=false; 
            }

            if(me.codpersonal != 0 || !me.fechaInicio || !me.fechaFin)
            {            
             codPersonal = this.codpersonal;
             Fecha,
             TiempoPresente=0;
             Tipo = "Entrada";
             Latitud=0;
             Longitud=0;
             Observaciones="";
             Condicion,

             axios.post("api/Horario/CrearEntradaAdmin", {
             codPersonal,
             Fecha,
             TiempoPresente,
             Tipo,
             Latitud,
             Longitud,  
             Observaciones,
             Condicion
             }).then(function(response) {
             me.dialog1=false;
             me.codpersonal=0;
             me.fechaInicio="";
             me.fechaFin="";
             me.listar();
             alert("!!! Has Fichado en la Empresa !!!");
             me.existeEntrada = true;
             })
             .catch(function(error) {
             console.log(error);
            });
            }
            else
            {
              alert("!!! Faltan datos completar !!!");
            }    
    },

    incidenciaAdmin (){
      let me = this;

        var date = this.fechaInicio;
        var time = this.fechaFin;
        var Fecha = moment.utc(date + ' ' + time);

            Tipo = "Incidencia";
            codPersonal = this.codpersonal;
            Observaciones = me.Observacionesinci;
            TipoIncidencia = me.tipoIncidencia;
            TiempoPresente=0;
            TiempoIncidencia=0;
            TotalHoras="";
            codCausa = me.codCausa;
            Latitud="";
            Longitud="";

            if(me.codCausa==0 || me.Observacionesinci.trim().length == 0)
            {
              window.alert("!!! Faltan datos por completar !!!");  
            }
            else
            {
              axios.post("api/Horario/CrearIncidencia", {
                codPersonal,
                Fecha,
                TiempoPresente,
                TiempoIncidencia,
                TotalHoras,
                Tipo,
                TipoIncidencia,
                codCausa,
                Latitud,
                Longitud,
                Observaciones 
              })
              .then(function(response) {
                alert("!!! Incidencia Enviada correctamente !!!");
                me.listar();
                me.close1();
                  me.recibeEntrada = false;
                  me.existeIncidencia=true;
              })
              .catch(function(error) {
                console.log(error);
              });
            }
    },



    EntradaHoraFecha(item)
    {
        let me = this;
        var date = this.fechaInicio;
        var time = this.fechaFin;
        var tipoEntradaSalida ="";
        me.Fecha = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
        me.codPersonal = item.codPersonal;          
        me.recibeCodPersona = item.codPersonal;

        me.tipoEntradaSalida = me.recibeTipoEntrada;
              //Verifica que la entrada Hora Fecha sea correcta de la entrada 
               axios.post("api/Horario/HoraFechaEntrada", {
               'codPersonal':me.codPersonal,
               'fecha':me.Fecha
                })
                .then(function(response) {
                    me.existeHoraFechaEntrada=response.data;              
                    
                    if(me.tipoEntradaSalida == "Entrada")
                    {
                         axios.post("api/Horario/ExisteEntradaAdmin",{
                         'codPersonal':me.codPersonal,
                         })
                        .then(function(response) {
                        me.existeEntrada=response.data;

                        if(me.existeEntrada)
                        {
                            ///Verifica si existe una Incidencia
                            axios.post("api/Horario/ExisteIncidenciaAdmin", {
                            'codPersonal':me.codPersonal,
                             })
                            .then(function(response) {
                             me.existeIncidencia=response.data;      
                             })
                            .catch(function(error) {
                            console.log(error);
                            });
                            if(me.existeIncidencia)
                            {
                                alert("!!! Tiene pendiente de cerrar una incidencia !!!");
                            }
                            else
                            {                                    
                               if(me.existeHoraFechaEntrada)                    
                               {
                                    if (window.confirm("!!! Está seguro que desea hacer la salida  !!!")) 
                                    {
                                        me.salidaAdmin();
                                        me.existeHoraFechaEntrada=false;
                                    }  
                                    }else
                                    {
                                        alert("!!! La hora o la fecha es inferior a la entrada actual  !!!");
                                    }                              
                                }                             
                            }                                                        
                        })
                        .catch(function(error) {
                        console.log(error); 
                        });                                                                                                   
                    }
                        if(me.tipoEntradaSalida == "Incidencia")
                        {
                            axios.post("api/Horario/HoraFechaIncidencia", {
                            'codPersonal':me.codPersonal,
                            'fecha':me.Fecha
                            })
                            .then(function(response) {
                             me.existeHoraFechaIncidencia=response.data; 

                                if(me.existeHoraFechaIncidencia)
                                {                                   
                                    if (window.confirm("!!! Está seguro que desea salir de la Incidencia  !!!")) 
                                    {
                                        me.salidaIncidencia();
                                        me.existeHoraFechaIncidencia=false;
                                    }                                      
                                } 
                                else
                                {
                                    alert("!!! La hora o la fecha es inferior a la entrada actual  !!!");
                                }                               
                            })
                            .catch(function(error) {
                            console.log(error);
                            }); 
                        }                       
                 })
                .catch(function(error) {
                console.log(error);
                });      
                 //Fin Verifica que la entrada Hora Fecha sea correcta de la entrada                                                                           
    },

    salidaAdmin (){
      let me = this;

        if(this.fechaInicio != "" && this.fechaFin != "")
        {
            var date = this.fechaInicio;
            var time = this.fechaFin;
            Fecha = moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm');
            var esadmin = me.soyAdmin;  
            
            if(esadmin == false)
            {
               Condicion=true;    
            }
            else
            {
               Condicion=false; 
            }

            codPersonal = me.recibeCodPersona;         
            idFichaje=this.idFichaje;
            Tipo = "Salida";
            Latitud = this.latitude;
            Longitud =this.longitude;
            TiempoPresente= this.TiempoPresente;
            var Observaciones = me.Observacionesinci;

            axios.post("api/Horario/SalirAdmin", {
                idFichaje,
                codPersonal,
                Fecha,
                TiempoPresente,
                Tipo,
                Latitud,
                Longitud,
                Observaciones,
                Condicion
                })
                .then(function(response) {
                me.dialog3=false;
                me.fechaInicio="";
                me.fechaFin="";                 
                alert("!!! Has Salido de la Empresa !!!");
                 me.existeEntrada = false;
                 me.entradaOlvidada = false;
                 me.incidenciaOlvidada = false;
                 me.listar();
                 me.close5(); 
                })
                .catch(function(error) {
                    console.log(error);  
                });  
            }    
        else
        {
             alert("!!! Faltan datos completar !!!");
        }     
    },
            filtrarfechas(){
                let me=this;
                codPersonal = this.$store.state.usuario.codpersonal
                axios.post('api/Usuarios/PresentesFiltrado',{
                    'fecha':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'codPersonal':codPersonal
                }).then(function(response){
                    me.presentes=null;
                    me.presentes=response.data;
                    me.close2();           
                }).catch(function(error){
                    console.log(error);
                });
            }, 

            editItem1 (item) {
                this.dialog3 = true;
                this.idFichaje=item.idFichaje;
                this.codPersonal = item.codPersonal;
                this.recibeTipoEntrada = item.tipo;
                this.nomEmpresa=item.nomEmpresa;
                this.nombre = item.nombre;
                this.nomDepartamento=item.nomDepartamento;
                this.rol = item.rol;
                this.fecha=item.fecha;
                this.hora=item.hora;           
            },

             editItem2 (item) {
                this.dialog4 = true;   
                this.idFichaje=item.idFichaje;
                this.codpersonal = item.codPersonal
                this.nomEmpresa=item.nomEmpresa;
                this.nombre = item.nombre;
                this.nomDepartamento=item.nomDepartamento;
                this.rol = item.rol;
                this.fecha=item.fecha;
                this.hora=item.hora;
       
            },

            selectPersonal(){
                let me=this;
                var personalArray=[];
                me.codPersonal = this.$store.state.usuario.codpersonal
                axios.post('api/Notificaciones/SelectPersonal',{
                 'codPersonal':me.codPersonal
                }).then(function(response){                    
                    personalArray=response.data;
                    personalArray.map(function(x){
                    me.usuarios.push({text: x.nombre,value:x.codPersonal});
                    });
                }).catch(function(error){
                    console.log(error);
                }); 
            },            

     salidaIncidencia()
    {
       let me = this;

        var date = this.fechaInicio;
        var time = this.fechaFin;
        
        var Fecha = moment.utc(date + ' ' + time);

        var esadmin = me.soyAdmin;  
            
            if(esadmin == false)
            {
               Condicion=true;    
            }
            else
            {
               Condicion=false; 
            }


        codPersonal = me.recibeCodPersona; 
        axios.post("api/Horario/SalirIncidenciaAdmin", 
        {
          codPersonal,
          Fecha,
          Condicion,
          }).then(function(response)
          {
               alert("!!! Has Salido de la Incidencia !!!");
               me.existeIncidencia=false;
               me.listar();
               me.close5();                            
          })
              .catch(function(error) 
              {
                console.log(error);
              });
      
    },

    selectCausa (){
                let me=this;
                var causaArray=[];
                axios.get('api/Causas/SelectCausa').then(function(response){
                    causaArray=response.data;
                    causaArray.map(function(x){
                    me.motivo.push({text: x.nomCausa,value:x.codCausa});
                    });
                }).catch(function(error){
                    console.log(error);
                });
    },

    esAdmin(){
        let me=this;
          codPersonal = this.$store.state.usuario.codpersonal
          axios.post('api/Horario/EsAdmin',{
                codPersonal,
                }).then(function(response){
                    me.soyAdmin=response.data;      
                }).catch(function(error){
                    console.log(error);
                });
        },

        actualizaPermiso(item){
        let me=this;
        me.codPersonal = item["codPersonal"]

          axios.post('api/Horario/ActualizaPermiso',{
                'codPersonal':me.codPersonal
                }).then(function(response){
                    me.permisoAdmin=response.data;  
                    me.listar();   
                    me.close7();    
                }).catch(function(error){
                    console.log(error);
                });
            } , */
    
    }
}
</script>
