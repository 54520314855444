<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg5 xl4>
            <v-card>
                <v-toolbar dark color="blue darken-3">
                    <v-toolbar-title>
                        Acceso al Sistema
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field prepend-icon="person" v-model="email" color="accent" label="Email" required>
                    </v-text-field>
                    <v-text-field prepend-icon="lock" v-model="password" type="password" color="accent" label="Password" required>
                    </v-text-field>
                    <v-flex class="red--text" v-if="error">
                        {{error}}
                    </v-flex>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                        <v-btn @click="ingresar" color="primary">Ingresar</v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
import Vue from 'vue';
export default {
    data(){
        return {
            email: '',
            password: '',
            error: null,  
            baja:'',
            pagination: {rowsPerPage: 10}          
        }
    },
    created () {

        },

    methods :{
        ingresar(){
            this.error=null;
            this.clienteactivo="";
            let me=this;

               axios.post('api/Usuarios/Login', {email: this.email, password: this.password})
                  .then(respuesta => {  
                        return respuesta.data                                                 
                    })
                   .then(data => {        
                        this.$store.dispatch("guardarToken", data.token)
                        this.$router.push({ name: 'home' })   
                        console.log("Tiene Pirineos: "+ this.$store.state.usuario.pirineos);                                                                                            
                    })
                    .catch(err => 
                    {         
                            if (err.response.status==400)
                            {
                               this.error="No es un email válido";
                            }else if (err.response.status==401){
                            this.error="Clienta esta dado de baja, consulte al Administrador";
                            }else if (err.response.status==404)
                            {
                            this.error="No existe el usuario o sus datos son incorrectos";
                            }else
                            {
                            this.error="Ocurrió un error";
                            }                        
                    })
                },

         VerificaPirineos(){
                let me=this;
                axios.get('api/Usuarios/VerificaPirineos').then(function(response){
                }).catch(function(error){
                    console.log(error);
                });
            },

        VerificaTablaVacia(){
                let me=this;
                axios.get('api/Usuarios/TablaPersonalVacia').then(function(response){              
                }).catch(function(error){
                    console.log(error);
                });
            },
            
    }
}
</script>

