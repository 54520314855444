<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Causas</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-btn slot="activator" color="primary" dark class="mb-2" v-if=!booltienePirineos>Nuevo</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm12 md12>
                                    <v-text-field disabled v-model="codcausa" label="Código" box></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field v-model="nombre" label="Nombre" box></v-text-field>
                                </v-flex>                               

                                <v-flex xs12 sm12 md12 v-show="valida">
                                    <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                    </div>
                                </v-flex>
                            
                                </v-layout>
                            </v-container>
                            </v-card-text>
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="guardar">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="adModal" max-width="290">
                        <v-card>
                            <v-card-title class="headline" v-if="adAccion==2">¿Activar Item?</v-card-title>
                            <v-card-title class="headline" v-if="adAccion==1">¿Desactivar Item?</v-card-title>
                            <v-card-text>
                                Estás a punto de 
                                <span v-if="adAccion==2">Activar </span>
                                <span v-if="adAccion==1">Desactivar </span>
                                el ítem {{ adNombre }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat="flat" @click="activarDesactivarCerrar">
                                    Cancelar
                                </v-btn>
                                <v-btn v-if="adAccion==2" color="orange darken-4" flat="flat" @click="activar">
                                    Activar
                                </v-btn>
                                <v-btn v-if="adAccion==1" color="orange darken-4" flat="flat" @click="desactivar">
                                    Desactivar
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="causas"
                :search="search"
                class="elevation-1"
                 :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                    <td class="justify-center layout px-0">
                        <v-icon
                        small
                        v-if=!booltienePirineos
                        class="mr-2"
                        @click="editItem(props.item)"
                        >
                        edit
                        </v-icon>
                        <template v-if="props.item.baja">
                            <v-icon
                            small
                            v-if=!booltienePirineos
                            @click="activarDesactivarMostrar(2,props.item)"
                            >
                            block
                            </v-icon>
                        </template>
                        <template v-else>
                            <v-icon
                            small
                            v-if=!booltienePirineos
                            @click="activarDesactivarMostrar(1,props.item)"
                            >
                            check
                            </v-icon>
                        </template>
                    </td>
                    <td style="display: none">{{ props.item.id }}</td>
                    <td>{{ props.item.descripcion }}</td>
                    <td>
                        <div v-if="props.item.baja">
                            <span class="red--text">Inactivo</span>
                        </div>
                        <div v-else>
                            <span class="blue--text">Activo</span>
                        </div>
                    </td>
                </template>
                <template slot="no-data">

                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import Vue from 'vue';

    export default {
        data(){
            return {
                causas:[],                
                dialog: false,
                headers: [
                    { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: 'Causa', value: 'nombre' },
                    { text: 'Estado', value: 'baja', sortable: false  }                
                ],
                search: '',
                editedIndex: -1,
                id: '',
                nombre: '',
                codcausa:'',
                valida: 0,
                validaMensaje:[],
                bolExiste:false,
                EstoyEditando:false,
                adModal: 0,
                adAccion: 0,
                adNombre: '',
                adId: '',
                booltienePirineos:'',
                pagination: {rowsPerPage: 12},                
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nueva Causa' : 'Actualizar Causa'
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
            this.SelectCausa();
            //this.pirineos();
        },
        
        methods:{
            listar(){
                let me=this;
                axios.post('api/Causas/Listar',{
                    'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    //console.log(response);
                    me.causas=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
            editItem (item) {
                this.codcausa=item.id;
                this.nombre=item.descripcion;
                this.editedIndex=1;
                this.dialog = true
            },

            close () {
                this.dialog = false;
                this.limpiar();
            },
            limpiar(){
                this.codcausa="";
                this.nombre="";
                this.editedIndex=-1;
                this.validaMensaje="";
            },

            guardar () {
                 let me=this;   
                this.bolExiste=false;
                this.EstoyEditando=false;

                if (this.validar()){
                    return;
                }
                    axios.post('api/Causas/ExisteCausa',{
                        'descripcion': me.nombre,
                        'idclienteoclock':this.$store.state.usuario.idclienteoclock
                        })                         
                    .then((response) => {                         
                        if(this.editedIndex > -1){//La data devuelve true si la empresa ya existe
                             this.EstoyEditando=true;                         
                        } 
                        if(response.data == true && this.EstoyEditando == false){
                           this.bolExiste = response.data;       
                           Vue . swal ("La causa: "+ this.nombre + " ya esta registrada");                         
                        }

                        if(this.EstoyEditando==true){                             
                             if (this.editedIndex > -1) {
                                //Código para editar
                                //Código para guardar
                                let me=this;
                                axios.post('api/Causas/Actualizar',{
                                     'id':me.codcausa,
                                     'descripcion': me.nombre,
                                     'idclienteoclock':this.$store.state.usuario.idclienteoclock
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                        }
                    
                            if (this.EstoyEditando==false && this.bolExiste==false){
                                //Código para guardar
                                let me=this;
                                axios.post('api/Causas/Crear',{
                                 'descripcion': me.nombre,
                                 'idclienteoclock':this.$store.state.usuario.idclienteoclock
                                }).then(function(response){
                                    me.close();
                                    me.listar();
                                    me.limpiar();                     
                                }).catch(function(error){
                                    console.log(error);
                                });
                            }
                    })
                        .catch((response) => {
                        console.log(response);
                    })
                    .then(() => {

                     });
            },

            validar(){
                this.valida=0;
                this.validaMensaje=[];

                if (this.nombre.length<3 || this.nombre.length>50){
                    this.validaMensaje.push("El nombre debe tener más de 3 caracteres y menos de 50 caracteres");
                }
                if (this.validaMensaje.length){
                    this.valida=1;
                }
                return this.valida;
            },
            activarDesactivarMostrar(accion,item){
                this.adModal=1;
                this.adNombre=item.nombre;
                this.adId=item.id;                
                if (accion==1){
                    this.adAccion=1;
                }
                else if (accion==2){
                    this.adAccion=2;
                }
                else{
                    this.adModal=0;
                }
            },
            activarDesactivarCerrar(){
                this.adModal=0;
            },
            activar(){
                let me=this;
                axios.post('api/Causas/Activar',{
                  'id':me.adId,
                  'idclienteoclock':this.$store.state.usuario.idclienteoclock
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            },
            desactivar(){
                let me=this;
                axios.post('api/Causas/Desactivar',{
                  'id':me.adId,
                  'idclienteoclock':this.$store.state.usuario.idclienteoclock
                  }).then(function(response){
                    me.adModal=0;
                    me.adAccion=0;
                    me.adNombre="";
                    me.adId="";
                    me.listar();                       
                }).catch(function(error){
                    console.log(error);
                });
            }, 
            
            SelectCausa(){
                let me=this;
                var causasArray=[];
                axios.post('api/Causas/SelectCausa',{
                'idclienteoclock':this.$store.state.usuario.idclienteoclock
                }).then(function(response){
                    causasArray=response.data;
                    causasArray.map(function(x){
                    me.causas.push({text: x.descripcion,value:x.id});
                    });
                }).catch(function(error){
                    console.log(error);
                });
            }, 

            pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },

            

        }        
    }
</script>
