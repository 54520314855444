<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Nominas</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn v-on:click="listar()"  color="primary">Reset</v-btn>                  
                    
                    <v-btn color="primary" @click="$refs.inputUpload.click()">NOMINAS</v-btn>
                        <input v-show="false" multiple ref="inputUpload" type="file" @change="onFileSelected" name="formFile">         

                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>                        
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                            <v-text-field v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y" type="date" box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>
                             </v-layout>
                            </v-container>
                            </v-card-text>
                            
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="nominas"
                :search="search"
                 class="elevation-1"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
            >
                <template slot="items" slot-scope="props">
                     <td class="justify-center layout px-0">
                         <v-icon
                        small
                        class="mr-2"
                        @click="Seleccionar(props.item)">
                        picture_as_pdf                                                    
                        </v-icon>

                        <!-- <v-btn color="primary" @click="$refs.inputUpload.click()">
                             <v-icon>assignment_returned</v-icon>
                        </v-btn>
                        <input v-show="false" ref="inputUpload" type="file" @change="Seleccionar(props.item)">                      -->
                    </td>
                    <td style="display:none">{{ props.item.idNomina }}</td>
                    <td style="display:none">{{ props.item.idPersonal }}</td>
                    <td>{{ props.item.nombre }}</td>
                    <td>{{ props.item.fecha }}</td>
                    <td>{{ props.item.url}}</td>                   

                </template>
                
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
     import Dropzone from 'vue2-dropzone'
     import Vue from 'vue';

    var idPersonal = "";

    export default {
        data(){
            return {
                nominas:[],                
                dialog: false,
                headers: [
                // { text: 'Opciones', value: 'opciones', sortable: false },
                    { text: '', value: '' },
                    { text: 'Empleado', value: 'nombre' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Url', value: 'url' },                  
                ],
               search: '',
                editedIndex: -1,
                fecha:'',
                nombre:'',
                nombreArchivo:'',
                fichero:'',
                fechaInicio:'',
                fechaFin:'',

                fechaInicio:null,
                fechaFin: null,
                pagination: {rowsPerPage: 12},
            }
        },
         computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Filtrar Nominas' : ''
            }
        },

        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.listar();
        },
        methods:{
            listar(){
                 let me=this;
                 me.idPersonal = this.$store.state.usuario.id;
                 console.log("Id Personal: "+me.idPersonal);
                 axios.post('api/Nominas/Listar',{
                  'idPersonal':me.idPersonal,
                }).then(function(response){
                    //console.log(response);
                    me.nominas=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },
        
            close () {
                this.dialog = false;
            },
    
            filtrarfechas(){
                let me=this;
                codPersonal = this.$store.state.usuario.codpersonal;
                axios.post('api/Documentos/NominasFiltrado',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    codPersonal
                }).then(function(response){
                    me.nominas=null;
                    me.nominas=response.data;
                    me.close();
                    me.limpiar();                     
                }).catch(function(error){
                    console.log(error);
                });
            },
            
            onFileSelected (event) {
               let me = this; 
               let formData = new FormData();
               const file = event.target.files[0];
               
               //const formData = new FormData();
               formData.append("formFile", file);
               axios.post('api/Nominas/Nominas',
               formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(){
                this.listar();
                console.log('SUCCESS!!');          
                })
                .catch(function(){
                console.log('FAILURE!!');
                });
            },

        
            Seleccionar (item) {
                let me=this;
                this.fichero=item.fichero;
                this.nombrearchivo = item.nombreArchivo                              
                
                axios.post('api/Documentos/DesgargarPDF',{
                    'fichero':this.fichero,
                    'nombreArchivo':this.nombrearchivo,
                }).then(function(response){  
                    
                  Vue . swal ("Nomina Guardada en C:\\Nominas\\OClock");                    
                }).catch(function(error){
                    console.log(error);

                });
            },

            DescargarPDF(){
                let me=this;
                this.Seleccionar(item);      

                axios.post('api/Documentos/DesgargarPDF',{
                    'fichero':me.item.fichero,
                    'nombreArchivo':me.item.nombreArchivo,
                }).then(function(response){

              
                }).catch(function(error){
                    console.log(error);
                });
            }, 

            limpiar(){
                this.fechaInicio="";
                this.fechaFin="";
            }


        }
      
    }
</script>
