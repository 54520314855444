import { render, staticRenderFns } from "./HorarioEmpresa.vue?vue&type=template&id=06b2dcde&"
import script from "./HorarioEmpresa.vue?vue&type=script&lang=js&"
export * from "./HorarioEmpresa.vue?vue&type=script&lang=js&"
import style0 from "./HorarioEmpresa.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "HorarioEmpresa.vue"
export default component.exports