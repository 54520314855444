<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn @click="crearPDF()"><v-icon>picture_as_pdf</v-icon></v-btn>
                <v-btn @click="Excel()"><v-icon>ad_units</v-icon></v-btn>
                <v-toolbar-title>Validar Fichajes</v-toolbar-title>
                    <v-divider
                    class="mx-2"
                    inset
                    vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>         
                    <v-btn color="primary" dark class="mb-2"  @click="AprobarEntradas()" v-if=!booltienePirineos>Aprobar</v-btn>         
                    
                    <v-btn slot="activator" color="primary" dark class="mb-2">Filtrar</v-btn>
                     <v-dialog v-model="dialog" max-width="700px">
                        <v-btn slot="activator" color="primary" dark class="mb-2" v-if=!booltienePirineos>Filtrar</v-btn>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ "" }}</span>
                            </v-card-title>
                
                            <v-card-text>
                            <v-container grid-list-md>
                             <v-layout wrap>
                               <v-flex xs12 sm6>
                                        <v-text-field type="date" v-model="fechaInicio" label="Fecha Inicio" dateformat="d M y"  box></v-text-field>
                                        </v-flex>

                                         <v-flex xs12 sm6>
                                           <v-text-field type="date" v-model="fechaFin" label="Fecha Fin" box></v-text-field>
                                        </v-flex>

                                       
                             </v-layout>
                            </v-container>
                            </v-card-text>                            
                
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click.native="close">Cancelar</v-btn>
                                <v-btn color="blue darken-1" flat @click.native="filtrarfechas">Aceptar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                   
                </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="reporteValidar"
                :search="search"
                :rows-per-page-items="[5,10, 20, 30, 40]"
                :pagination.sync="pagination">
                <template slot="items" slot-scope="props">
                    <tr :class="[props.item.validado ? 'bg-green' : '']">
                        <td>
                        <input type="checkbox"  v-model="props.item.validado" >
                        </td>                                                                              
                        <td style="display: none">{{ props.item.id}}</td>
                        <td>{{ props.item.fecha | filterDate }}</td>
                        <td>{{ props.item.fecha2}}</td>
                        <td>{{ props.item.nombre }}</td>
                        <td style="display: none">{{ props.item.tipo }}</td>
                        <td>{{ props.item.observaciones }}</td>
                        <td style="display: none">{{ props.item.nomEmpresa }}</td>
                        <!-- <td>
                            <div v-if="props.item.condicion">
                                <span class="blue--text">Aprobado</span>
                            </div>
                            <div v-else>
                                <span class="red--text">Denegado</span>
                            </div> 
                        </td> -->
                    </tr>
                </template>
                            
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import axios from 'axios'
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable';
    import moment from "moment";
    import Vue from 'vue';


    export default {
        //Este filtro es util para darle formato a un valor y meterlo en la tabla
        filters: {
            filterDate: function (value) {
                return moment(value).format('DD/MM/YYYY')
            },

            minutos: function (value) {
                return this.minutosTrabajados();
            }

        },
        data(){
            return {
                selected: [],
                usuarios:[], 
                reporteValidar:[],          
                dialog: false,
                headers: [
                    //{ text: 'Select All', value: 'opciones', sortable: false }, 
                    { text: 'Seleccionar',sortable: false},      
                    { text: 'Fecha', value: 'fecha' },      
                    { text: 'Hora', value: 'fecha2' },                      
                    { text: 'Nombre', value: 'nombre' },
                   // { text: 'Tipo Fichaje', value: 'tipo' },                     
                    { text: 'Observación', value: 'observaciones' },           
                    //{ text: 'Condición', value: 'condicion' },      

                ],
               search: '',
                editedIndex: -1,
                id: '',
                idrol:'',
                tipo:'',
                fecha:'',
                nombre:'',
                apellido1:'',
                apellido2:'',
                fechaInicio: '',
                fechaFin: '',
                pagination: {rowsPerPage: 12},
                codPersonal:'',
                validado:false,
                isCheckAll: false,
                capturaEmpresa:'',
                aProbar:[],
                resultado:[],
                numeroRepetido:[],
                arrayUnicoIds:[],
                IdClienteOclock:null,
                booltienePirineos:false,
            }
        },
       
        watch: {
            dialog (val) {
            val || this.close()
            }
        },

        created () {
            this.ListarvalidarPermiso();
            this.pirineos();
        },
        methods:{
            crearPDF(){
                var columns = [
                    {title: "Fecha ", dataKey: "fecha"}, 
                    {title: "Hora ", dataKey: "fecha2"}, 
                    {title: "Nombre", dataKey: "nombre"},   
                    //{title: "Tipo Fichaje", dataKey: "tipo"},
                    {title: "Observación", dataKey: "observaciones"},
                ];
 

                var rows = [];
                let me = this;
                
               
                this.reporteValidar.map(function(x){
                    rows.push({fecha:moment(x.fecha).format("DD/MM/YYYY"),fecha2:x.fecha2,nombre:x.nombre,tipo:x.tipo,observaciones:x.observaciones});                    
                });
                  // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 100},
                    addPageContent: function(data) {
                        doc.text("Informe de Validación :   " +moment(me.capturaFechaIncicio ).format('DD/MM/YYYY')+" hasta "+moment(me.capturaFechaFin).format('DD/MM/YYYY'), 40, 30);
                        
                        doc.setFontSize(12);
                        doc.setTextColor(40);
                        doc.setFontStyle('normal');
                        doc.text("Empresa: :  "+ me.capturaEmpresa,40,60);

                    }
                });
                doc.save('Informe_Validar.pdf');
            },

            
            close () {
                this.dialog = false;
            },

            footer(){ 
                doc.text(150,285, 'page ' + doc.page); //print number bottom right
                doc.page ++;
            },

            
         limpiar(){
                this.fechaInicio="";
                this.fechaFin="";
         },

         Excel(){
                let me=this;
                me.codigocliente=this.$store.state.usuario.idclienteoclock;

                axios.post('api/Informes/ValidarFichajesExcel', {
                    'idclienteoclock':me.codigocliente                  
                })
                .then(function(response){
                    //me.empresas=response.data;   
                    Vue . swal ("El Archivo se ha descargado en: C:/ExcelOClock/");           
                                  
                })
                .catch(function(error){
                    console.log(error);
                });
            },


           
            filtrarfechas(){
                
                let me=this;
                me.IdClienteOclock = this.$store.state.usuario.idclienteoclock
                axios.post('api/Informes/InformeValidarFichajes',{
                    'fecha1':me.fechaInicio,
                    'fecha2':me.fechaFin,
                    'idclienteoclock':me.IdClienteOclock
                }).then(function(response){
                    me.reporteValidar=response.data;
                    //me.capturaEmpresa = me.reporteValidar[0].nomEmpresa;
                    Vue . swal ("!! No hay datos que mostrar !!");           
                    me.close();
                    me.limpiar();   
                                        
                }).catch(function(error){
                    console.log(error);
                });
            },

       /* arrayAprobados(item){
            let me=this;

            try 
            {          
                me.resultado.push(item);   
                me.veces++;
                console.log("Resultado: "+me.resultado);
             
            } catch (error) 
            {
                console.log(error);
            }
        },*/

        AprobarEntradas()
        {
           let me=this;
           //me.resultado.sort(); // Los ordenamos para hacer la comparación que he comentado antes

           me.arrayUnicoIds = this.reporteValidar.filter( el => {
               return el.validado;
           }).map( el => {
               return el.id
           })

           console.log("Array: "+me.arrayUnicoIds);
           me.IdClienteOclock = this.$store.state.usuario.idclienteoclock
                 
                if(me.arrayUnicoIds=="")
                {
                     Vue . swal ("!!! Debe de Seleccionar un elemento !!!");
                }
                else
                {
                    var statusConfirm2 = confirm("¿Está seguro que desea Aprobar?");
                    if (statusConfirm2 == true)
                    {
                        for(var j = 0; j < me.arrayUnicoIds.length; j++) 
                        {
                                axios.post('api/Horario/PermisoAprobado',{
                                'id':me.arrayUnicoIds[j],
                                'idclienteoclock':this.$store.state.usuario.idclienteoclock        
                            }).then(function(response){
                                me.ListarvalidarPermiso();
                            }).catch(function(error){
                                console.log(error);
                            });
                        }
                    }
                }
        },

        
        
             ListarvalidarPermiso(){
                let me=this;
                axios.post('api/Informes/ListarFichajesNoAprobados',{
                    //me.capturaEmpresa = me.reporteValidar[0].nomEmpresa,
                   'idclienteoclock':this.$store.state.usuario.idclienteoclock        
                }).then(function(response){
                    me.reporteValidar=response.data;
                }).catch(function(error){
                   
                    console.log(error);
                });
            },   
            
            pirineos(){  
            let me = this;

            axios.post('api/ClienteOclocks/TienePirineos',{
                 'codigo':this.$store.state.usuario.idclienteoclock
            }).then(function(response){
                me.booltienePirineos = response.data;
                }).catch(function(error){
                    console.log(error);
            });      
            },
        }        
    }
</script>

<style>
.bg-red {
    background-color: #FED7D7 !important;
}

.bg-green{
    background-color: #c6f6d5 !important;
}

</style>